// in src/authProvider.js
import { getRole } from '../configs/Apollo'
import Cognito from '../configs/Cognito'

const authProvider = {
  login: async ({ username, password }) => {
    await Cognito.signIn(username, password)
  },
  checkError: error => {
    const status = error.status
    if (status === 401 || status === 403) {
      Cognito.signOut()
      return Promise.reject()
    }

    return Promise.resolve()
  },
  checkAuth: async () => {
    const session = await Cognito.getSession()
    return session.isValid() ? Promise.resolve() : Promise.reject()
  },
  logout: () => {
    Cognito.signOut()
    return Promise.resolve()
  },
  getIdentity: async () => {
    const session = await Cognito.getSession()
    const payload = session.getAccessToken().decodePayload()
    return {
      id: payload.sub,
      fullName: payload.username,
      groups: (payload && payload['cognito:groups']) || [],
    }
  },
  // authorization
  getPermissions: () => {
    return getRole()
  },
}

export default authProvider
