import { Create, SimpleForm, TextInput, required } from 'react-admin'

export const ChildUserCreate = props => {
  return (
    <Create {...props} redirect={(_r, _id, data) => `User/${data.parentId}/show`}>
      <SimpleForm>
        <TextInput source="parent" disabled validate={required()} />
        <TextInput source="firstName" validate={required()} />
        <TextInput source="lastName" validate={required()} />
        <TextInput source="email" validate={required()} />
        <TextInput source="username" validate={required()} />
        <TextInput source="company" />
        <TextInput source="phoneNumber" />
      </SimpleForm>
    </Create>
  )
}
