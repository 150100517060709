"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripeCouponData = exports.useCouponData = exports.StripeCustomerFragment = exports.StripeInvoiceFragment = exports.StripeDiscountFragment = exports.StripeCouponFragment = exports.StripeInvoiceItemFragment = exports.StripeSubscriptionFragment = exports.StripeSubscriptionItemFragment = exports.StripePlanFragment = exports.StripeCardFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.StripeCardFragment = (0, client_1.gql) `
  fragment StripeCardFragment on StripeCardType {
    id
    brand
    name
    last4
    expMonth
    expYear
    country
    addressCity
    addressCountry
    addressLine1
    addressLine2
    addressState
    addressZip
  }
`;
exports.StripePlanFragment = (0, client_1.gql) `
  fragment StripePlanFragment on StripePlanType {
    id
    amount
    nickname
    active
    billingScheme
    interval
    intervalCount
    tiersMode
    metadata
    productType
    tiers {
      flatAmount
      unitAmount
      upTo
    }
    trialPeriodDays
  }
`;
exports.StripeSubscriptionItemFragment = (0, client_1.gql) `
  fragment StripeSubscriptionItemFragment on StripeSubscriptionItemType {
    id
    created
    plan {
      ...StripePlanFragment
    }
    quantity
    subscription
  }
  ${exports.StripePlanFragment}
`;
exports.StripeSubscriptionFragment = (0, client_1.gql) `
  fragment StripeSubscriptionFragment on StripeSubscriptionType {
    id
    applicationFeePercent
    cancelAtPeriodEnd
    canceledAt
    currentPeriodEnd
    currentPeriodStart
    customer
    daysUntilDue
    defaultSource
    endedAt
    status
    taxPercent
    trialEnd
    trialStart
    queryCount
    totalQueries
    plan {
      ...StripePlanFragment
    }
    items {
      data {
        ...StripeSubscriptionItemFragment
      }
    }
  }
  ${exports.StripePlanFragment}
  ${exports.StripeSubscriptionItemFragment}
`;
exports.StripeInvoiceItemFragment = (0, client_1.gql) `
  fragment StripeInvoiceItemFragment on StripeInvoiceItemType {
    amount
    currency
    description
    plan {
      ...StripePlanFragment
    }
  }
  ${exports.StripePlanFragment}
`;
exports.StripeCouponFragment = (0, client_1.gql) `
  fragment StripeCouponFragment on StripeCouponType {
    id
    # object
    amountOff
    # created
    currency
    # duration
    # durationInMonths
    # livemode
    # maxRedemptions
    metadata
    name
    percentOff
    # redeemBy
    # timesRedeemed
    valid
  }
`;
exports.StripeDiscountFragment = (0, client_1.gql) `
  fragment StripeDiscountFragment on StripeDiscountType {
    # object
    customer
    # end
    # start
    subscription
    coupon {
      ...StripeCouponFragment
    }
  }
  ${exports.StripeCouponFragment}
`;
exports.StripeInvoiceFragment = (0, client_1.gql) `
  fragment StripeInvoiceFragment on StripeInvoiceType {
    id
    billing
    billingReason
    description
    date
    discount {
      ...StripeDiscountFragment
    }
    dueDate
    defaultSource
    invoicePdf
    lines {
      data {
        ...StripeInvoiceItemFragment
      }
    }
    number
    receiptNumber
    subtotal
    tax
    taxPercent
    total
  }
  ${exports.StripeDiscountFragment}
  ${exports.StripeInvoiceItemFragment}
`;
exports.StripeCustomerFragment = (0, client_1.gql) `
  fragment StripeCustomerFragment on StripeCustomerType {
    defaultSource
    sources {
      data {
        ...StripeCardFragment
      }
    }
    subscriptions {
      data {
        ...StripeSubscriptionFragment
      }
    }
  }
  ${exports.StripeCardFragment}
  ${exports.StripeSubscriptionFragment}
`;
const COUPON_QUERY = (0, client_1.gql) `
  query coupon($id: ID!) {
    coupon(id: $id) {
      ...StripeCouponFragment
    }
  }
  ${exports.StripeCouponFragment}
`;
const useCouponData = (options) => {
    return (0, client_1.useQuery)(COUPON_QUERY, options);
};
exports.useCouponData = useCouponData;
exports.StripeCouponData = (0, utils_1.buildQuery)({
    query: COUPON_QUERY,
});
