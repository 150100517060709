"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvgPriceData = exports.PricesData = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const PriceFragment = (0, client_1.gql) `
  fragment PriceFragment on Price {
    id
    listPrice
    periodType
    effectiveDate
  }
`;
const PRICES_QUERY = (0, client_1.gql) `
  query prices($partId: String!) {
    prices(partId: $partId) {
      ...PriceFragment
    }
  }
  ${PriceFragment}
`;
exports.PricesData = (0, utils_1.buildQuery)({
    query: PRICES_QUERY,
});
const AveragePriceFragment = (0, client_1.gql) `
  fragment AveragePriceFragment on AveragePrice {
    id
    periodEndDate
    periodStartDate
    averagePrice
    hospitalCount
    quantity
    periodType
  }
`;
const AVGPRICE_QUERY = (0, client_1.gql) `
  query averagePrices($condition: AveragePriceInput!) {
    averagePrices(condition: $condition) {
      ...AveragePriceFragment
    }
  }
  ${AveragePriceFragment}
`;
exports.AvgPriceData = (0, utils_1.buildQuery)({
    query: AVGPRICE_QUERY,
});
