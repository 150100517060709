"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConstructValidationsData = exports.ConstructValidationFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.ConstructValidationFragment = (0, client_1.gql) `
  fragment ConstructValidationFragment on ConstructValidationType {
    id
    conId
    ruleType
    message
    ddiFilter
    ddiThen
    cleanRule
    convertedRule
  }
`;
const CONSTRUCT_VALIDATIONS_QUERY_ALL = (0, client_1.gql) `
  query constructValidations {
    constructValidations {
      ...ConstructValidationFragment
    }
  }
  ${exports.ConstructValidationFragment}
`;
const useConstructValidationsData = (options) => {
    return (0, utils_1.useQueryFunction)(CONSTRUCT_VALIDATIONS_QUERY_ALL, options);
};
exports.useConstructValidationsData = useConstructValidationsData;
