import { Newsletter } from '@curvo/apollo'
import React from 'react'
import {
  ChipField,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin'

export const NewsletterShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <FunctionField
        label="Published"
        render={(item: Newsletter) =>
          item.publishMonth && item.publishYear && `${item.publishMonth}/${item.publishYear}`
        }
      />
      <TextField source="issue" />
      <TextField source="section" />
      <FunctionField
        label="Download Url"
        render={(item: Newsletter) => {
          return (
            <a target="_blank" href={item.downloadUrl!} onClick={e => e.stopPropagation()}>
              Link
            </a>
          )
        }}
      />
      <ReferenceArrayField label="Tags" reference="Tag" source="tagsIds">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField label="Categories" reference="Category" source="category.id" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)
