"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllowedGicsMaterialData = exports.ALLOWED_GIC_MATERIAL_QUERY = exports.GicMaterialFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.GicMaterialFragment = (0, client_1.gql) `
  fragment GicMaterialFragment on Material {
    id
    name
  }
`;
exports.ALLOWED_GIC_MATERIAL_QUERY = (0, client_1.gql) `
  query AllowedGicMaterial($gicId: Int) {
    allowedGicsMaterial(gicId: $gicId) {
      ...GicMaterialFragment
    }
  }
  ${exports.GicMaterialFragment}
`;
exports.AllowedGicsMaterialData = (0, utils_1.buildQuery)({
    query: exports.ALLOWED_GIC_MATERIAL_QUERY,
});
