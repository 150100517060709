"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateGicTypeTwoMutation = exports.UPDATE_GIC_TYPE_TWO_MUTATION = exports.createGicTypeTwoMutation = exports.CREATE_GIC_TYPE_TWO_MUTATION = exports.updateAllowedGicTypeTwoMutation = exports.UPDATE_ALLOWED_GIC_TYPE_TWO_MUTATION = exports.AllowedGicsTypeTwoData = exports.ALLOWED_GIC_TYPE_TWO_QUERY = exports.GicsTypeTwoData = exports.GICS_TYPE_TWO_QUERY = exports.GicTypeTwoFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.GicTypeTwoFragment = (0, client_1.gql) `
  fragment GicTypeTwoFragment on GicTypeTwo {
    id
    includes
    excludes
    name
  }
`;
exports.GICS_TYPE_TWO_QUERY = (0, client_1.gql) `
  query GicsTypeTwo {
    gicsTypeTwo {
      ...GicTypeTwoFragment
    }
  }
  ${exports.GicTypeTwoFragment}
`;
exports.GicsTypeTwoData = (0, utils_1.buildQuery)({
    query: exports.GICS_TYPE_TWO_QUERY,
});
exports.ALLOWED_GIC_TYPE_TWO_QUERY = (0, client_1.gql) `
  query AllowedGicTypeTwo($gicId: Int, $typeOneId: String) {
    allowedGicsTypeTwo(gicId: $gicId, typeOneId: $typeOneId) {
      ...GicTypeTwoFragment
    }
  }
  ${exports.GicTypeTwoFragment}
`;
exports.AllowedGicsTypeTwoData = (0, utils_1.buildQuery)({
    query: exports.ALLOWED_GIC_TYPE_TWO_QUERY,
});
exports.UPDATE_ALLOWED_GIC_TYPE_TWO_MUTATION = (0, client_1.gql) `
  mutation updateAllowedGicTypeTwos($input: AllowedTypeTwoUpdatesInput!) {
    updateAllowedGicTypeTwos(input: $input) {
      gicTypeOneId
      gicTypeTwoId
      updateType
    }
  }
`;
exports.updateAllowedGicTypeTwoMutation = (0, utils_1.mutateFunction)({ mutation: exports.UPDATE_ALLOWED_GIC_TYPE_TWO_MUTATION });
exports.CREATE_GIC_TYPE_TWO_MUTATION = (0, client_1.gql) `
  mutation createGicTypeTwo($input: CreateGicTypeTwoInput!) {
    createGicTypeTwo(input: $input) {
      ...GicTypeTwoFragment
    }
  }
  ${exports.GicTypeTwoFragment}
`;
exports.createGicTypeTwoMutation = (0, utils_1.mutateFunction)({
    mutation: exports.CREATE_GIC_TYPE_TWO_MUTATION,
});
exports.UPDATE_GIC_TYPE_TWO_MUTATION = (0, client_1.gql) `
  mutation updateGicTypeTwo($input: UpdateGicTypeTwoInput!) {
    updateGicTypeTwo(input: $input) {
      ...GicTypeTwoFragment
    }
  }
  ${exports.GicTypeTwoFragment}
`;
exports.updateGicTypeTwoMutation = (0, utils_1.mutateFunction)({ mutation: exports.UPDATE_GIC_TYPE_TWO_MUTATION });
