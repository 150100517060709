import { User, adminConfirmUser, adminSetUserTemporaryPassword } from '@curvo/apollo'
import { Menu, MenuItem, MenuProps } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Alert } from '@mui/material'
import React, { useCallback, useState } from 'react'
import {
  BulkDeleteButton,
  Button,
  CreateButton,
  Datagrid,
  EmailField,
  ExportButton,
  Filter,
  FunctionField,
  List,
  TextField,
  TextInput,
  TopToolbar,
  useNotify,
  useRecordContext,
} from 'react-admin'
import Client from '../../configs/Apollo'
import Cognito from '../../configs/Cognito'

export const UserList = props => {
  const [selectedRow, setSelectedRow] = useState<User | null>(null)
  const [rowMenuRef, setRowMenuRef] = useState<null | HTMLElement>(null)
  const notify = useNotify()

  const openActionMenu = (row: User, event: React.MouseEvent<HTMLElement>) => {
    setSelectedRow(row)
    setRowMenuRef(event.currentTarget)
  }

  const handleMenuItemClick = useCallback(
    (key: string) => {
      if (!selectedRow) {
        return
      }
      switch (key) {
        case 'confirm':
          adminConfirmUser({ username: selectedRow.username })
            .then(() => notify('Confirm successfully!', { type: 'success' }))
            .catch(e => {
              console.error(e)
              notify('Failed to confirm user!', { type: 'error' })
            })
          return
        case 'reset-password':
          adminSetUserTemporaryPassword({ username: selectedRow.username })
            .then(data =>
              notify(
                <Alert
                  severity="success"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() =>
                        data?.data?.setUserTemporaryPassword &&
                        navigator.clipboard.writeText(data?.data?.setUserTemporaryPassword.toString())
                      }>
                      COPY
                    </Button>
                  }>{`Reset user's password successfully! Temporary password is: ${data?.data?.setUserTemporaryPassword}`}</Alert>,
                {
                  autoHideDuration: null,
                },
              ),
            )
            .catch(e => {
              console.error(e)
              notify('Failed to reset user password!', { type: 'error' })
            })
          return
      }
    },
    [selectedRow, notify],
  )

  return (
    <div>
      <List {...props} bulkActionButtons={null} filters={<UserFilter />} actions={<Actions />}>
        <Datagrid rowClick="show" bulkActionButtons={<RowActions />}>
          <TextField source="username" sortable={true} />
          <EmailField source="email" sortable={true} />
          <TextField source="firstName" sortable={false} />
          <TextField source="lastName" sortable={false} />
          <TextField source="isActive" sortable={false} label="Status" />
          <TextField source="company" sortable={true} />
          <TextField source="phoneNumber" sortable={false} />
          <FunctionField
            label="Subscriptions"
            render={(user: User) =>
              user.paymentInfo &&
              user.paymentInfo.subscriptions &&
              user.paymentInfo.subscriptions.data.map(
                item =>
                  item.plan && (
                    <div key={item.id}>
                      {`${item.plan!.id.match(/news/) ? 'Newsletter' : 'Find A Part'} (${item.plan.nickname})`}
                    </div>
                  ),
              )
            }
          />
          <TextField source="role" sortable={false} />
          <RowInlineActions openActionMenu={openActionMenu} />
        </Datagrid>
      </List>
      <RowInlineActionsMenu
        options={[
          { key: 'confirm', title: 'Confirm' },
          { key: 'reset-password', title: 'Reset Password' },
        ]}
        anchorEl={rowMenuRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(selectedRow)}
        onClose={() => {
          setSelectedRow(null)
          setRowMenuRef(null)
        }}
        handleMenuItemClick={handleMenuItemClick}
      />
    </div>
  )
}

const Actions: React.FC<any> = ({ total, resource, currentSort, filterValues, exporter }) => {
  const clearSubscriptionCache = useCallback(async () => {
    try {
      const session = await Cognito.getSession()
      await fetch(`http://${Client.getUrl()}/clearCache`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${session.getAccessToken().getJwtToken()}`,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }, [])
  return (
    <TopToolbar>
      <Button color="primary" onClick={clearSubscriptionCache}>
        Clear Subscription Cache
      </Button>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <CreateButton />
    </TopToolbar>
  )
}
const UserFilter: React.FC<{}> = props => (
  <Filter {...props}>
    <TextInput label="Search by Username" source="searchText" alwaysOn />
  </Filter>
)

const RowActions: React.FC<any> = () => {
  return (
    <TopToolbar>
      <BulkDeleteButton />
    </TopToolbar>
  )
}

const RowInlineActions: React.FC<any> = ({ openActionMenu }) => {
  const record = useRecordContext()
  return (
    <>
      <Button
        disabled={!record.isActive}
        onClick={e => {
          e.stopPropagation()
          openActionMenu(record, e)
        }}>
        <MoreVert />
      </Button>
    </>
  )
}

type MenuOptionsType = { key: string; title: string; icon?: JSX.Element }

const RowInlineActionsMenu: React.FC<
  {
    options: MenuOptionsType[]
    handleMenuItemClick: (key: string) => void
  } & MenuProps
> = ({ options, handleMenuItemClick, ...props }) => {
  return (
    <Menu id="user-mngt" elevation={1} {...props}>
      {options.map((option: MenuOptionsType) => (
        <MenuItem onClick={() => handleMenuItemClick(option.key)} key={option.key}>
          {option.icon || null}
          {option.title}
        </MenuItem>
      ))}
    </Menu>
  )
}
