"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UdiFragment = void 0;
const client_1 = require("@apollo/client");
// import { buildQuery } from '../helpers/utils'
// import { Udi } from '../models'
exports.UdiFragment = (0, client_1.gql) `
  fragment UdiFragment on Udi {
    id
    gmdnName
    version
    deviceDescription
    brandName
    company
    issueAgent
    udiSize
    kitFlag
    containsDi
  }
`;
// export const UDIS_QUERY = gql`
//   query Udis {
//     udis {
//       ...UdiFragment
//     }
//   }
//   ${UdiFragment}
// `
// export type UdiResponse = {
//   udi: Udi;
// }
// export const UdisData = buildQuery<UdiResponse[]>({ query: UDIS_QUERY })
