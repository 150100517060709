"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSubSegmentMutation = exports.SUBSEGMENT_DELETE_MUTATION = exports.createSubSegmentMutation = exports.SUBSEGMENT_CREATE_MUTATION = exports.updateSubSegmentMutation = exports.SUBSEGMENT_UPDATE_MUTATION = exports.SubSegmentsData = exports.SUB_SEGMENTS_QUERY = exports.SubSegmentFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const pageInfo_1 = require("./pageInfo");
exports.SubSegmentFragment = (0, client_1.gql) `
  fragment SubSegmentFragment on SubSegment {
    id
    name
  }
`;
exports.SUB_SEGMENTS_QUERY = (0, client_1.gql) `
  query SubSegments($before: String, $after: String, $first: Int, $last: Int, $skip: Int, $name: String) {
    subSegments(before: $before, after: $after, first: $first, last: $last, skip: $skip, name: $name) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...SubSegmentFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.SubSegmentFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.SubSegmentsData = (0, utils_1.buildQuery)({
    query: exports.SUB_SEGMENTS_QUERY,
});
exports.SUBSEGMENT_UPDATE_MUTATION = (0, client_1.gql) `
  mutation updateSubSegment($input: SubSegmentUpdateInput!) {
    updateSubSegment(input: $input) {
      ...SubSegmentFragment
    }
  }
  ${exports.SubSegmentFragment}
`;
exports.updateSubSegmentMutation = (0, utils_1.mutateFunction)({
    mutation: exports.SUBSEGMENT_UPDATE_MUTATION,
});
exports.SUBSEGMENT_CREATE_MUTATION = (0, client_1.gql) `
  mutation createSubSegment($input: SubSegmentCreateInput!) {
    createSubSegment(input: $input) {
      ...SubSegmentFragment
    }
  }
  ${exports.SubSegmentFragment}
`;
exports.createSubSegmentMutation = (0, utils_1.mutateFunction)({
    mutation: exports.SUBSEGMENT_CREATE_MUTATION,
});
exports.SUBSEGMENT_DELETE_MUTATION = (0, client_1.gql) `
  mutation deleteSubSegment($id: Int!) {
    deleteSubSegment(id: $id) {
      ...SubSegmentFragment
    }
  }
  ${exports.SubSegmentFragment}
`;
exports.deleteSubSegmentMutation = (0, utils_1.mutateFunction)({
    mutation: exports.SUBSEGMENT_DELETE_MUTATION,
});
