import { CreateNewsletterMutationArgs, UpdateNewsletterMutationArgs } from '@curvo/apollo'
import {
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'

type T = UpdateNewsletterMutationArgs | CreateNewsletterMutationArgs
export const validatePublished = (values: T) => {
  if ((!values.publishMonth && values.publishYear) || (values.publishMonth && !values.publishYear)) {
    return {
      publishMonth: !values.publishMonth && ['Required'],
      publishYear: !values.publishYear && ['Required'],
    }
  }

  if (values.publishMonth && (+values.publishMonth < 1 || +values.publishMonth > 12)) {
    return {
      publishMonth: ['Invalid month'],
    }
  }
  if (values.publishYear && (+values.publishYear < 1000 || +values.publishYear > 9999)) {
    return {
      publishYear: ['Invalid year'],
    }
  }
  return {}
}

export const NewsletterEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm validate={validatePublished}>
        <TextInput source="name" />
        <TextInput source="publishMonth" type="number" />
        <TextInput source="publishYear" type="number" />
        <TextInput source="issue" />
        <TextInput source="section" type="number" />
        <ReferenceArrayInput label="Tags" resource="" source="tagsIds" reference="Tag">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput label="Category" source="category.id" reference="Category">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput multiline source="description" />
      </SimpleForm>
    </Edit>
  )
}
