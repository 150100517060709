"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateGicTypeOneMutation = exports.UPDATE_GIC_TYPE_ONE_MUTATION = exports.createGicTypeOneMutation = exports.CREATE_GIC_TYPE_ONE_MUTATION = exports.AllowedGicsTypeOneData = exports.ALLOWED_GIC_TYPE_ONE_QUERY = exports.GicsTypeOneData = exports.GICS_TYPE_ONE_QUERY = exports.GicTypeOneUpdateFragment = exports.GicTypeOneFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.GicTypeOneFragment = (0, client_1.gql) `
  fragment GicTypeOneFragment on GicTypeOne {
    id
    includes
    excludes
    name
  }
`;
exports.GicTypeOneUpdateFragment = (0, client_1.gql) `
  fragment GicTypeOneFragment on GicTypeOne {
    id
    name
  }
`;
exports.GICS_TYPE_ONE_QUERY = (0, client_1.gql) `
  query GicsTypeOne {
    gicsTypeOne {
      ...GicTypeOneFragment
    }
  }
  ${exports.GicTypeOneFragment}
`;
exports.GicsTypeOneData = (0, utils_1.buildQuery)({
    query: exports.GICS_TYPE_ONE_QUERY,
});
exports.ALLOWED_GIC_TYPE_ONE_QUERY = (0, client_1.gql) `
  query AllowedGicTypeOne($gicId: Int) {
    allowedGicsTypeOne(gicId: $gicId) {
      ...GicTypeOneFragment
    }
  }
  ${exports.GicTypeOneFragment}
`;
exports.AllowedGicsTypeOneData = (0, utils_1.buildQuery)({
    query: exports.ALLOWED_GIC_TYPE_ONE_QUERY,
});
exports.CREATE_GIC_TYPE_ONE_MUTATION = (0, client_1.gql) `
  mutation createGicTypeOne($input: CreateGicTypeOneInput!) {
    createGicTypeOne(input: $input) {
      ...GicTypeOneFragment
    }
  }
  ${exports.GicTypeOneFragment}
`;
exports.createGicTypeOneMutation = (0, utils_1.mutateFunction)({ mutation: exports.CREATE_GIC_TYPE_ONE_MUTATION });
exports.UPDATE_GIC_TYPE_ONE_MUTATION = (0, client_1.gql) `
  mutation updateGicTypeOne($input: UpdateGicTypeOneInput!) {
    updateGicTypeOne(input: $input) {
      ...GicTypeOneFragment
    }
  }
  ${exports.GicTypeOneFragment}
`;
exports.updateGicTypeOneMutation = (0, utils_1.mutateFunction)({ mutation: exports.UPDATE_GIC_TYPE_ONE_MUTATION });
