"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSegmentationMutation = exports.updateSegmentationMutation = exports.createSegementationMutation = exports.SegmentationData = exports.SEGMENTATION_QUERY = exports.SegmentationFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const segment_1 = require("./segment");
const subSegment_1 = require("./subSegment");
exports.SegmentationFragment = (0, client_1.gql) `
  fragment SegmentationFragment on Segmentation {
    id
    segment {
      ...SegmentFragment
    }
    subSegment {
      ...SubSegmentFragment
    }
  }
  ${segment_1.SegmentFragment}
  ${subSegment_1.SubSegmentFragment}
`;
exports.SEGMENTATION_QUERY = (0, client_1.gql) `
  query Segmentations {
    segmentations {
      ...SegmentationFragment
    }
  }
  ${exports.SegmentationFragment}
`;
exports.SegmentationData = (0, utils_1.buildQuery)({
    query: exports.SEGMENTATION_QUERY,
});
const CREATE_SEGMENTATION_MUTATION = (0, client_1.gql) `
  mutation createSegmentation($input: SegmentationCreateInput!) {
    createSegmentation(input: $input) {
      ...SegmentationFragment
    }
  }
  ${exports.SegmentationFragment}
`;
exports.createSegementationMutation = (0, utils_1.mutateFunction)({
    mutation: CREATE_SEGMENTATION_MUTATION,
});
const UPDATE_SEGMENTATION_MUTATION = (0, client_1.gql) `
  mutation updateSegmentation($input: SegmentationUpdateInput!) {
    updateSegmentation(input: $input) {
      ...SegmentationFragment
    }
  }
  ${exports.SegmentationFragment}
`;
exports.updateSegmentationMutation = (0, utils_1.mutateFunction)({
    mutation: UPDATE_SEGMENTATION_MUTATION,
});
const DELETE_SEGMENTATION_MUTATION = (0, client_1.gql) `
  mutation deleteSegmentation($id: String!) {
    deleteSegmentation(id: $id) {
      ...SegmentationFragment
    }
  }
  ${exports.SegmentationFragment}
`;
exports.deleteSegmentationMutation = (0, utils_1.mutateFunction)({
    mutation: DELETE_SEGMENTATION_MUTATION,
});
