"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteBrandMutation = exports.createBrandMutation = exports.updateBrandMutation = exports.BrandsData = exports.BRANDS_QUERY = exports.BrandFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const manufacturer_1 = require("./manufacturer");
const pageInfo_1 = require("./pageInfo");
exports.BrandFragment = (0, client_1.gql) `
  fragment BrandFragment on Brand {
    id
    name
    isInactive
  }
`;
exports.BRANDS_QUERY = (0, client_1.gql) `
  query Brands(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $searchText: String
    $manufacturerId: String
    $isInactive: Boolean
  ) {
    brands(
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      searchText: $searchText
      manufacturerId: $manufacturerId
      isInactive: $isInactive
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...BrandFragment
          manufacturer {
            ...ManufacturerFragment
          }
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.BrandFragment}
  ${manufacturer_1.ManufacturerFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.BrandsData = (0, utils_1.buildQuery)({
    query: exports.BRANDS_QUERY,
    fetchMoreOpts: ({ variables, data }) => {
        const pageInfo = data && data.brands && data.brands.pageInfo;
        if (!pageInfo || !pageInfo.hasNextPage) {
            return undefined;
        }
        return {
            variables: { first: variables === null || variables === void 0 ? void 0 : variables.first, after: pageInfo.endCursor },
            updateQuery: (p, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.brands) {
                    return p;
                }
                const pEdges = p.brands.edges || [];
                const nEdges = fetchMoreResult.brands.edges || [];
                return {
                    brands: {
                        ...p.brands,
                        edges: [...pEdges, ...nEdges],
                        pageInfo: fetchMoreResult.brands.pageInfo,
                    },
                };
            },
        };
    },
});
const BRAND_UPDATE = (0, client_1.gql) `
  mutation updateBrand($input: BrandUpdateInput!) {
    updateBrand(input: $input) {
      ...BrandFragment
      manufacturer {
        ...ManufacturerFragment
      }
    }
  }
  ${exports.BrandFragment}
  ${manufacturer_1.ManufacturerFragment}
`;
exports.updateBrandMutation = (0, utils_1.mutateFunction)({ mutation: BRAND_UPDATE });
const BRAND_CREATE = (0, client_1.gql) `
  mutation addBrand($input: BrandCreateInput!) {
    addBrand(input: $input) {
      ...BrandFragment
      manufacturer {
        ...ManufacturerFragment
      }
    }
  }
  ${exports.BrandFragment}
  ${manufacturer_1.ManufacturerFragment}
`;
exports.createBrandMutation = (0, utils_1.mutateFunction)({ mutation: BRAND_CREATE });
const BRAND_DELETE = (0, client_1.gql) `
  mutation deleteBrand($id: ID!) {
    deleteBrand(id: $id) {
      ...BrandFragment
    }
  }
  ${exports.BrandFragment}
`;
exports.deleteBrandMutation = (0, utils_1.mutateFunction)({ mutation: BRAND_DELETE });
