"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateFileMappingMutation = exports.createFileMappingMutation = exports.FileMappingsData = exports.FILE_MAPPING = exports.FileMappingFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.FileMappingFragment = (0, client_1.gql) `
  fragment FileMappingFragment on FileMappingType {
    id
    name
    vendor
    manufacturer
    mfgitem
    description
    facitem
    ponum
    datepurchase
    uom
    uomconversion
    unitprice
    qtypurchase
    extprice
    contract
    venitem
    facilityId
    surgeon
    waste
    clusterId
    priceTiers
  }
`;
exports.FILE_MAPPING = (0, client_1.gql) `
  query fileMappings(
    $first: Int
    $last: Int
    $skip: Int
    $before: String
    $after: String
    $input: FileMappingQueryInput
  ) {
    fileMappings(first: $first, last: $last, skip: $skip, before: $before, after: $after, input: $input) {
      edges {
        node {
          ...FileMappingFragment
        }
      }
    }
  }
  ${exports.FileMappingFragment}
`;
exports.FileMappingsData = (0, utils_1.buildQuery)({
    query: exports.FILE_MAPPING,
});
const NEW_FILE_MAPPING = (0, client_1.gql) `
  mutation createFileMapping($input: CreateFileMappingInput!) {
    createFileMapping(input: $input) {
      ...FileMappingFragment
    }
  }
  ${exports.FileMappingFragment}
`;
exports.createFileMappingMutation = (0, utils_1.mutateFunction)({
    mutation: NEW_FILE_MAPPING,
});
const UPDATE_FILE_MAPPING = (0, client_1.gql) `
  mutation editFileMapping($input: UpdateFileMappingInput!, $id: ID!) {
    editFileMapping(input: $input, id: $id) {
      ...FileMappingFragment
    }
  }
  ${exports.FileMappingFragment}
`;
exports.updateFileMappingMutation = (0, utils_1.mutateFunction)({
    mutation: UPDATE_FILE_MAPPING,
});
