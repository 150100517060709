"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBatchNewPricesQuery = exports.BATCH_LIST_QUERY = exports.deleteAllPricesMutation = exports.DELETE_ALL_QUEUED_PRICES = exports.CountParsedPrices = exports.COUNT_QUEUED_PRICES = exports.updatePricesFromQueueMutation = exports.INSERT_QUEUED_PRICES = exports.approveNewPricesMutation = exports.APPROVE_NEW_PRICES = exports.deleteDiscardedNewPricesMutation = exports.DELETE_DISCARDED_NEW_PRICES = exports.QueuedNewPricesData = exports.QUEUE_NEW_PRICES = exports.parsePricesMutation = exports.PARSE_PRICES = exports.generateBulkUpdateFileUploadUrlMutation = exports.GENERATE_BULK_UPDATE_FILE_UPLOAD_URL = exports.ParsePriceResultFragment = exports.NewPriceFragment = exports.BulkUpdateFileUploadInfoFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const pageInfo_1 = require("./pageInfo");
exports.BulkUpdateFileUploadInfoFragment = (0, client_1.gql) `
  fragment BulkUpdateFileUploadInfoFragment on BulkUpdateFileUploadInfo {
    key
    uploadUrl
  }
`;
exports.NewPriceFragment = (0, client_1.gql) `
  fragment NewPriceFragment on NewPrice {
    partId
    strippedPartNumber
    inputName
    newInputName
    quantityPerBox
    manufacturerId
    listPrice
    newListPrice
    effectiveDate
    periodType
    isApproved
    isNotFound
  }
`;
exports.ParsePriceResultFragment = (0, client_1.gql) `
  fragment ParsePriceResultFragment on ParsePricesResult {
    total
    discarded
    valid
  }
`;
exports.GENERATE_BULK_UPDATE_FILE_UPLOAD_URL = (0, client_1.gql) `
  mutation generateBulkUpdateFileUploadUrl($extension: String) {
    generateBulkUpdateFileUploadUrl(extension: $extension) {
      ...BulkUpdateFileUploadInfoFragment
    }
  }
  ${exports.BulkUpdateFileUploadInfoFragment}
`;
exports.generateBulkUpdateFileUploadUrlMutation = (0, utils_1.mutateFunction)({
    mutation: exports.GENERATE_BULK_UPDATE_FILE_UPLOAD_URL,
});
exports.PARSE_PRICES = (0, client_1.gql) `
  mutation parsePrices($input: NewPricesInput!) {
    parsePrices(input: $input) {
      ...ParsePriceResultFragment
    }
  }
  ${exports.ParsePriceResultFragment}
`;
exports.parsePricesMutation = (0, utils_1.mutateFunction)({
    mutation: exports.PARSE_PRICES,
});
exports.QUEUE_NEW_PRICES = (0, client_1.gql) `
  query queuedNewPrices(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $manufacturerId: String
    $type: NewPricesDataType!
    $batchName: String!
  ) {
    queuedNewPrices(
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      manufacturerId: $manufacturerId
      type: $type
      batchName: $batchName
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...NewPriceFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${pageInfo_1.PageInfoFragment}
  ${exports.NewPriceFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.QueuedNewPricesData = (0, utils_1.buildQuery)({
    query: exports.QUEUE_NEW_PRICES,
});
exports.DELETE_DISCARDED_NEW_PRICES = (0, client_1.gql) `
  mutation deleteDiscardedPrices {
    deleteDiscardedPrices
  }
`;
exports.deleteDiscardedNewPricesMutation = (0, utils_1.mutateFunction)({
    mutation: exports.DELETE_DISCARDED_NEW_PRICES,
});
exports.APPROVE_NEW_PRICES = (0, client_1.gql) `
  mutation approvePricesToInsert($input: ApprovePricesInput!) {
    approvePricesToInsert(input: $input) {
      partId
    }
  }
`;
exports.approveNewPricesMutation = (0, utils_1.mutateFunction)({
    mutation: exports.APPROVE_NEW_PRICES,
});
exports.INSERT_QUEUED_PRICES = (0, client_1.gql) `
  mutation updatePricesFromQueue($batchName: String!) {
    updatePricesFromQueue(batchName: $batchName)
  }
`;
exports.updatePricesFromQueueMutation = (0, utils_1.mutateFunction)({ mutation: exports.INSERT_QUEUED_PRICES });
exports.COUNT_QUEUED_PRICES = (0, client_1.gql) `
  query countParsedPrices {
    countParsedPrices {
      valid
      discarded
      total
      approved
    }
  }
`;
exports.CountParsedPrices = (0, utils_1.buildQuery)({
    query: exports.COUNT_QUEUED_PRICES,
});
exports.DELETE_ALL_QUEUED_PRICES = (0, client_1.gql) `
  mutation deleteAllPrices($batchName: String!) {
    deleteAllPrices(batchName: $batchName)
  }
`;
exports.deleteAllPricesMutation = (0, utils_1.mutateFunction)({ mutation: exports.DELETE_ALL_QUEUED_PRICES });
exports.BATCH_LIST_QUERY = (0, client_1.gql) `
  query pricesBatchNameList {
    pricesBatchNameList
  }
`;
const useBatchNewPricesQuery = () => (0, utils_1.useQueryFunction)(exports.BATCH_LIST_QUERY, {});
exports.useBatchNewPricesQuery = useBatchNewPricesQuery;
