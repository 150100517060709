"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeBlocklistMatchingsMutation = exports.useLazyBlocklistMatchingsQuery = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const BLOCKLIST_MATCHINGS = (0, client_1.gql) `
  query blocklistMatchings(
    $selectedFields: [String!]
    $startRow: Int!
    $endRow: Int!
    $sorting: [SortModel!]!
    $filter: [FilterModel!]!
  ) {
    blocklistMatchings(
      selectedFields: $selectedFields
      startRow: $startRow
      endRow: $endRow
      sorting: $sorting
      filter: $filter
    ) {
      edges {
        node {
          id
          vendor
          venitem
          manufacturer
          mfgitem
          description

          blockMatchId
          blockMatchCatalog
          blockManufacturer
          blockMfgitem
          blockDescription

          createdAt
          updatedAt

          createdByUser {
            id
            username
          }
        }
      }
      metadata {
        total
      }
    }
  }
`;
const useLazyBlocklistMatchingsQuery = (options) => (0, utils_1.useLazyQueryFunction)(BLOCKLIST_MATCHINGS, options);
exports.useLazyBlocklistMatchingsQuery = useLazyBlocklistMatchingsQuery;
const REMOVE_BLOCKLIST_MATCHING = (0, client_1.gql) `
  mutation removeBlocklistMatchings($ids: [String!]!) {
    removeBlocklistMatchings(ids: $ids)
  }
`;
exports.removeBlocklistMatchingsMutation = (0, utils_1.mutateFunction)({ mutation: REMOVE_BLOCKLIST_MATCHING });
