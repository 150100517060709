"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDefaultCreditCard = exports.removeCreditCard = exports.editCardWithPhoneNumber = exports.editCreditCard = exports.addCreditCardWithPhoneNumber = exports.addCreditCardWithPhoneNumberAndSubscription = exports.addCreditCard = exports.removeSubscription = exports.REMOVE_SUBSCRIPTION_MUTATION = exports.changeSubscription = exports.UPDATE_SUBSCRIPTION_MUTATION = exports.addSubscription = exports.ADD_SUBSCRIPTION_MUTATION = exports.deactivateAccount = exports.DEACTIVATE_ACCOUNT_MUTATION = exports.updateMe = exports.MeWOPaymentData = exports.MeData = exports.ME_W_O_PAYMENT_QUERY = exports.ME_QUERY = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const invoice_1 = require("./invoice");
const stripe_1 = require("./stripe");
const user_1 = require("./user");
const MeFragment = (0, client_1.gql) `
  fragment MeFragment on User {
    ...UserFragment
    paymentInfo {
      ...StripeCustomerFragment
    }
    addressLineOne
    addressLineTwo
    city
    state
    zip
    wantHardCopy
    role
    createdAt
    lastLogin
  }
  ${user_1.UserFragment}
  ${stripe_1.StripeCustomerFragment}
`;
const MeWOPaymentFragment = (0, client_1.gql) `
  fragment MeWOPaymentFragment on User {
    ...UserFragment
    addressLineOne
    addressLineTwo
    city
    state
    zip
    wantHardCopy
    role
  }
  ${user_1.UserFragment}
`;
exports.ME_QUERY = (0, client_1.gql) `
  query me {
    me {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.ME_W_O_PAYMENT_QUERY = (0, client_1.gql) `
  query me {
    me {
      ...MeWOPaymentFragment
    }
  }
  ${MeWOPaymentFragment}
`;
exports.MeData = (0, utils_1.buildQuery)({ query: exports.ME_QUERY });
exports.MeWOPaymentData = (0, utils_1.buildQuery)({ query: exports.ME_W_O_PAYMENT_QUERY });
// Update me
const UPDATE_ME = (0, client_1.gql) `
  mutation updateMe(
    $firstName: String
    $lastName: String
    $company: String
    $phoneNumber: String
    $addressLineOne: String
    $addressLineTwo: String
    $city: String
    $state: String
    $zip: String
    $wantHardCopy: Boolean
  ) {
    updateMe(
      firstName: $firstName
      lastName: $lastName
      company: $company
      phoneNumber: $phoneNumber
      addressLineOne: $addressLineOne
      addressLineTwo: $addressLineTwo
      city: $city
      state: $state
      zip: $zip
      wantHardCopy: $wantHardCopy
    ) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.updateMe = (0, utils_1.mutateFunction)({
    mutation: UPDATE_ME,
});
exports.DEACTIVATE_ACCOUNT_MUTATION = (0, client_1.gql) `
  mutation deactivateAccount {
    deactivateAccount {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.deactivateAccount = (0, utils_1.mutateFunction)({
    mutation: exports.DEACTIVATE_ACCOUNT_MUTATION,
});
// Add Subscription
exports.ADD_SUBSCRIPTION_MUTATION = (0, client_1.gql) `
  mutation addSubscription($planId: String!, $coupon: String) {
    addSubscription(planId: $planId, coupon: $coupon) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.addSubscription = (0, utils_1.mutateFunction)({
    mutation: exports.ADD_SUBSCRIPTION_MUTATION,
});
// Update Subscription
exports.UPDATE_SUBSCRIPTION_MUTATION = (0, client_1.gql) `
  mutation changeSubscription($planId: String!, $coupon: String) {
    changeSubscription(planId: $planId, coupon: $coupon) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.changeSubscription = (0, utils_1.mutateFunction)({
    mutation: exports.UPDATE_SUBSCRIPTION_MUTATION,
    refetchQueries: [{ query: exports.ME_QUERY }, { query: invoice_1.UPCOMING_INVOICE_QUERY }],
});
// Remove Subscription
exports.REMOVE_SUBSCRIPTION_MUTATION = (0, client_1.gql) `
  mutation removeSubscription($subId: String!) {
    removeSubscription(subId: $subId) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.removeSubscription = (0, utils_1.mutateFunction)({
    mutation: exports.REMOVE_SUBSCRIPTION_MUTATION,
    refetchQueries: [{ query: exports.ME_QUERY }, { query: invoice_1.UPCOMING_INVOICE_QUERY }],
});
// Link Credit Card to User
const ADD_CREDIT_CARD_MUTATION = (0, client_1.gql) `
  mutation addCreditCard($input: CardInputType, $tokenId: String!) {
    addCreditCard(input: $input, tokenId: $tokenId) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.addCreditCard = (0, utils_1.mutateFunction)({
    mutation: ADD_CREDIT_CARD_MUTATION,
});
const ADD_CREDIT_CARD_AND_SUBSCRIPTION_MUTATION = (0, client_1.gql) `
  mutation addCreditCardAndSubscription(
    $input: CardInputType
    $tokenId: String!
    $planIds: [String!]!
    $coupon: String
    $phoneNumber: String
  ) {
    addCreditCardAndDoSubscription(input: $input, tokenId: $tokenId, planIds: $planIds, coupon: $coupon) {
      ...MeFragment
    }
    updateMe(phoneNumber: $phoneNumber) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.addCreditCardWithPhoneNumberAndSubscription = (0, utils_1.mutateFunction)({
    mutation: ADD_CREDIT_CARD_AND_SUBSCRIPTION_MUTATION,
});
const ADD_CARD_WITH_PHONENUMBER = (0, client_1.gql) `
  mutation addCreditCardWithPhoneNumber($input: CardInputType, $tokenId: String!, $phoneNumber: String) {
    addCreditCard(input: $input, tokenId: $tokenId) {
      ...MeFragment
    }
    updateMe(phoneNumber: $phoneNumber) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.addCreditCardWithPhoneNumber = (0, utils_1.mutateFunction)({
    mutation: ADD_CARD_WITH_PHONENUMBER,
});
const EDIT_CREDIT_CARD_MUTATION = (0, client_1.gql) `
  mutation editCreditCard($cardId: String!, $input: CardInputType!) {
    editCreditCard(cardId: $cardId, input: $input) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.editCreditCard = (0, utils_1.mutateFunction)({
    mutation: EDIT_CREDIT_CARD_MUTATION,
});
const EDIT_CREDIT_CARD_WITH_PHONENUMBER_MUTATION = (0, client_1.gql) `
  mutation editCardWithPhoneNumber($cardId: String!, $input: CardInputType!, $phoneNumber: String) {
    editCreditCard(cardId: $cardId, input: $input) {
      ...MeFragment
    }
    updateMe(phoneNumber: $phoneNumber) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.editCardWithPhoneNumber = (0, utils_1.mutateFunction)({
    mutation: EDIT_CREDIT_CARD_WITH_PHONENUMBER_MUTATION,
});
const REMOVE_CREDIT_CARD_MUTATION = (0, client_1.gql) `
  mutation removeCreditCard($cardId: String!) {
    removeCreditCard(cardId: $cardId) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.removeCreditCard = (0, utils_1.mutateFunction)({
    mutation: REMOVE_CREDIT_CARD_MUTATION,
});
// Link Credit Card to User
const SET_DEFAULT_PAYMENT_METHOD_MUTATION = (0, client_1.gql) `
  mutation setDefaultCreditCard($cardId: String!) {
    setDefaultCreditCard(cardId: $cardId) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
exports.setDefaultCreditCard = (0, utils_1.mutateFunction)({
    mutation: SET_DEFAULT_PAYMENT_METHOD_MUTATION,
});
