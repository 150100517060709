import { Auth } from '@curvo/cognito'
import env from './env'
import Client from './Apollo'

const poolData = {
  UserPoolId: env.COGNITO_POOL_ID,
  ClientId: env.COGNITO_CLIENT_ID,
}

const Cognito = new Auth(poolData)

export const signOut = () => {
  Cognito.signOut()
  Client.clear()
}

export default Cognito
