"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurvoError = void 0;
class CurvoError extends Error {
    constructor(message, code, cause, context) {
        super(message);
        this.code = code;
        this.cause = cause;
        this.context = context;
    }
}
exports.CurvoError = CurvoError;
