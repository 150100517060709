"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteMaterialMutation = exports.MATERIAL_DELETE = exports.materialCreateMutation = exports.MATERIAL_CREATE = exports.materialUpdateMutation = exports.MATERIAL_UPDATE = exports.MaterialsData = exports.MATERIALS_QUERY = exports.MaterialFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const pageInfo_1 = require("./pageInfo");
exports.MaterialFragment = (0, client_1.gql) `
  fragment MaterialFragment on Material {
    id
    name
    longName
  }
`;
exports.MATERIALS_QUERY = (0, client_1.gql) `
  query materials(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $searchText: String
    $orderBy: MaterialsOrderByEnum
    $direction: SortOrderEnum
  ) {
    materials(
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      searchText: $searchText
      orderBy: $orderBy
      direction: $direction
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...MaterialFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.MaterialFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.MaterialsData = (0, utils_1.buildQuery)({
    query: exports.MATERIALS_QUERY,
    fetchMoreOpts: ({ variables, data }) => {
        const pageInfo = data && data.materials && data.materials.pageInfo;
        if (!pageInfo || !pageInfo.hasNextPage) {
            return undefined;
        }
        return {
            variables: { first: variables === null || variables === void 0 ? void 0 : variables.first, after: pageInfo.endCursor },
            updateQuery: (p, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.materials) {
                    return p;
                }
                const pEdges = p.materials.edges || [];
                const nEdges = fetchMoreResult.materials.edges || [];
                return {
                    materials: {
                        ...p.materials,
                        edges: [...pEdges, ...nEdges],
                        pageInfo: fetchMoreResult.materials.pageInfo,
                    },
                };
            },
        };
    },
});
exports.MATERIAL_UPDATE = (0, client_1.gql) `
  mutation updateMaterial($input: MaterialUpdateInput!) {
    updateMaterial(input: $input) {
      ...MaterialFragment
    }
  }
  ${exports.MaterialFragment}
`;
exports.materialUpdateMutation = (0, utils_1.mutateFunction)({
    mutation: exports.MATERIAL_UPDATE,
});
exports.MATERIAL_CREATE = (0, client_1.gql) `
  mutation createMaterial($input: MaterialCreateInput!) {
    createMaterial(input: $input) {
      ...MaterialFragment
    }
  }
  ${exports.MaterialFragment}
`;
exports.materialCreateMutation = (0, utils_1.mutateFunction)({
    mutation: exports.MATERIAL_CREATE,
});
exports.MATERIAL_DELETE = (0, client_1.gql) `
  mutation deleteMaterial($id: String!) {
    deleteMaterial(id: $id) {
      ...MaterialFragment
    }
  }
  ${exports.MaterialFragment}
`;
exports.deleteMaterialMutation = (0, utils_1.mutateFunction)({
    mutation: exports.MATERIAL_DELETE,
});
