"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.env = exports.ENV_URI = exports.QA_URI = exports.CURVO_URI = void 0;
const URI = {
    DEV: 'api-dev-onn.curvo.cloud',
    // DEV: 'localhost:3000',
    QA: 'api-qa-onn.curvolabs.com',
    PROD: 'api-onn.curvolabs.com',
};
const env = (process.env.CURVO_ENV ||
    process.env.REACT_APP_CURVO_ENV ||
    process.env.GATSBY_CURVO_ENV);
exports.env = env;
exports.CURVO_URI = (env && URI[env]) || URI.DEV;
exports.QA_URI = 'api-qa-onn.curvolabs.com';
exports.ENV_URI = env === 'PROD' ? 'api-onn.curvolabs.com' : (env && URI[env]) || URI.DEV;
console.log('Curvo Gen', env);
