import React from 'react'
import { Edit, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from 'react-admin'

const UserEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const UserEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <SelectInput
          source="isActive"
          label="Status"
          choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Deactive', name: 'Deactive' },
          ]}
        />
        <TextInput source="company" />
        <TextInput source="phoneNumber" />
        <SelectInput
          source="role"
          choices={[
            { id: 'CurvoAdmin', name: 'CurvoAdmin' },
            { id: 'User', name: 'User' },
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}
