"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createManufacturerMutation = exports.CREATE_MANUFACTURER = exports.updateManufacturerMutation = exports.UPDATE_MANUFACTURER = exports.deleteManufacturer = exports.ManufacturersData = exports.MANUFACTURERS_QUERY = exports.ManufacturerFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const pageInfo_1 = require("./pageInfo");
exports.ManufacturerFragment = (0, client_1.gql) `
  fragment ManufacturerFragment on Manufacturer {
    id
    name
    city
    state
    country
    type
    url
    ticker
    shortName
    producesReprocessedItems
    comments
    retry {
      id
      name
    }
  }
`;
exports.MANUFACTURERS_QUERY = (0, client_1.gql) `
  query Manufacturers(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $searchText: String
    $orderBy: ManufacturersOrderByEnum
    $direction: SortOrderEnum
  ) {
    manufacturers(
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      searchText: $searchText
      orderBy: $orderBy
      direction: $direction
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...ManufacturerFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.ManufacturerFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.ManufacturersData = (0, utils_1.buildQuery)({
    query: exports.MANUFACTURERS_QUERY,
    fetchMoreOpts: ({ variables, data }) => {
        const pageInfo = data && data.manufacturers && data.manufacturers.pageInfo;
        if (!pageInfo || !pageInfo.hasNextPage) {
            return undefined;
        }
        return {
            variables: { first: variables === null || variables === void 0 ? void 0 : variables.first, after: pageInfo.endCursor },
            updateQuery: (p, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.manufacturers) {
                    return p;
                }
                const pEdges = p.manufacturers.edges || [];
                const nEdges = fetchMoreResult.manufacturers.edges || [];
                return {
                    manufacturers: {
                        ...p.manufacturers,
                        edges: [...pEdges, ...nEdges],
                        pageInfo: fetchMoreResult.manufacturers.pageInfo,
                    },
                };
            },
        };
    },
});
const DELETE_MANUFACTURER = (0, client_1.gql) `
  mutation deleteManufacturer($id: ID!) {
    deleteManufacturer(id: $id) {
      ...ManufacturerFragment
    }
  }
  ${exports.ManufacturerFragment}
`;
exports.deleteManufacturer = (0, utils_1.mutateFunction)({
    mutation: DELETE_MANUFACTURER,
});
exports.UPDATE_MANUFACTURER = (0, client_1.gql) `
  mutation updateManufacturer($input: UpdateManufacturerInput!) {
    updateManufacturer(input: $input) {
      ...ManufacturerFragment
    }
  }
  ${exports.ManufacturerFragment}
`;
exports.updateManufacturerMutation = (0, utils_1.mutateFunction)({
    mutation: exports.UPDATE_MANUFACTURER,
});
exports.CREATE_MANUFACTURER = (0, client_1.gql) `
  mutation createManufacturer($input: CreateManufacturerInput!) {
    createManufacturer(input: $input) {
      ...ManufacturerFragment
    }
  }
  ${exports.ManufacturerFragment}
`;
exports.createManufacturerMutation = (0, utils_1.mutateFunction)({
    mutation: exports.CREATE_MANUFACTURER,
});
