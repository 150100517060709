"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBatchNewPartListQuery = exports.BATCH_NEW_PART_LIST_QUERY = exports.CountQueuedParts = exports.COUNT_QUEUED_PARTS = exports.clearNewPartsTableMutation = exports.CLEAR_QUEUED_PARTS = exports.bulkUpdateQueuedPartMutation = exports.QUEUED_PARTS_BULK_UPDATE = exports.insertPartsFromQueueMutation = exports.INSERT_PARTS_FROM_QUEUE = exports.approveQueuedUpdatePartsMutation = exports.APPROVE_QUEUED_UPDATE_PART = exports.QueuedUpdatePartsData = exports.QUEUED_UPDATE_PARTS = exports.parsePartsMutation = exports.PARSE_PARTS = exports.QueuedUpdatePartFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const pageInfo_1 = require("./pageInfo");
exports.QueuedUpdatePartFragment = (0, client_1.gql) `
  fragment QueuedUpdatePartFragment on NewPart {
    id
    partId
    partName
    partNumber
    strippedPartNumber
    description
    inputName
    id_510k
    mfgSize
    partAttributes
    dataSource
    side
    isSterile
    isCustom
    isReprocessed
    sizeOne
    sizeTwo
    sizeThree
    quantityPerBox
    uom
    data
    manufacturer
    manufacturerId
    brand
    brandId
    productLine
    productLineId
    mtlProductLine
    gic
    gicId
    typeOne
    typeOneId
    typeTwo
    typeTwoId
    material
    materialId
    segmentation
    segmentationId
    isReady
    createdAt
    updatedAt
  }
`;
exports.PARSE_PARTS = (0, client_1.gql) `
  mutation parseParts($input: NewBulkUpdatePartsInput!) {
    parseParts(input: $input)
  }
`;
exports.parsePartsMutation = (0, utils_1.mutateFunction)({
    mutation: exports.PARSE_PARTS,
});
exports.QUEUED_UPDATE_PARTS = (0, client_1.gql) `
  query queuedUpdateParts(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $type: UpdatePartDataType!
    $batchName: String!
  ) {
    queuedUpdateParts(
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      type: $type
      batchName: $batchName
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...QueuedUpdatePartFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${pageInfo_1.PageInfoFragment}
  ${exports.QueuedUpdatePartFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.QueuedUpdatePartsData = (0, utils_1.buildQuery)({
    query: exports.QUEUED_UPDATE_PARTS,
});
exports.APPROVE_QUEUED_UPDATE_PART = (0, client_1.gql) `
  mutation approvePartsToUpdate($input: ApprovePartsToUpdateInputType!) {
    approvePartsToUpdate(input: $input) {
      ...QueuedUpdatePartFragment
    }
  }
  ${exports.QueuedUpdatePartFragment}
`;
exports.approveQueuedUpdatePartsMutation = (0, utils_1.mutateFunction)({
    mutation: exports.APPROVE_QUEUED_UPDATE_PART,
});
exports.INSERT_PARTS_FROM_QUEUE = (0, client_1.gql) `
  mutation insertPartsFromQueue($batchName: String!) {
    insertPartsFromQueue(batchName: $batchName)
  }
`;
exports.insertPartsFromQueueMutation = (0, utils_1.mutateFunction)({
    mutation: exports.INSERT_PARTS_FROM_QUEUE,
});
exports.QUEUED_PARTS_BULK_UPDATE = (0, client_1.gql) `
  mutation bulkUpdateNewParts($input: BulkUpdateNewPartInputType!) {
    bulkUpdateNewParts(input: $input) {
      ...QueuedUpdatePartFragment
    }
  }
  ${exports.QueuedUpdatePartFragment}
`;
exports.bulkUpdateQueuedPartMutation = (0, utils_1.mutateFunction)({
    mutation: exports.QUEUED_PARTS_BULK_UPDATE,
});
exports.CLEAR_QUEUED_PARTS = (0, client_1.gql) `
  mutation clearNewPartsTable($batchName: String!) {
    clearNewPartsTable(batchName: $batchName)
  }
`;
exports.clearNewPartsTableMutation = (0, utils_1.mutateFunction)({ mutation: exports.CLEAR_QUEUED_PARTS });
exports.COUNT_QUEUED_PARTS = (0, client_1.gql) `
  query countQueuedParts {
    countQueuedParts {
      valid
      withErrors
      total
    }
  }
`;
exports.CountQueuedParts = (0, utils_1.buildQuery)({
    query: exports.COUNT_QUEUED_PARTS,
});
exports.BATCH_NEW_PART_LIST_QUERY = (0, client_1.gql) `
  query partsBatchNameList {
    partsBatchNameList
  }
`;
const useBatchNewPartListQuery = () => (0, utils_1.useQueryFunction)(exports.BATCH_NEW_PART_LIST_QUERY, {});
exports.useBatchNewPartListQuery = useBatchNewPartListQuery;
