import { Role, StripeProductEnum, StripeProductType, getSubscriptionProducts } from '@curvo/apollo'
import { Alert } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Button,
  Create,
  FormDataConsumer,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  usePermissions,
  useRedirect,
} from 'react-admin'

const PostSaveButton = () => {
  const notify = useNotify()
  const onSuccess = data => {
    notify(
      <Alert
        severity="success"
        action={
          <Button color="inherit" size="small" onClick={() => navigator.clipboard.writeText(data.tempPassword)}>
            COPY
          </Button>
        }>{`User "${data.username}" created with temporary password: ${data.tempPassword}`}</Alert>,
      {
        autoHideDuration: null,
        type: 'success',
      },
    )
  }
  return <SaveButton type="button" mutationOptions={{ onSuccess }} />
}

const UserCreateToolbar = props => {
  const redirect = useRedirect()

  return (
    <Toolbar {...props}>
      <PostSaveButton />
      <Button onClick={() => redirect('/User')}>Cancel</Button>
    </Toolbar>
  )
}

function getProductByType(data: StripeProductType[], type: StripeProductEnum) {
  return data.find(item => item.type === type && item.active === true)
}

export const UserCreate = props => {
  const { permissions } = usePermissions()

  const [newsletterPlans, setNewsletterPlans] = useState<any[]>([])
  const [fapPlans, setFapPlans] = useState<any[]>([])
  const [plansLoading, setPlansLoading] = useState(true)

  useEffect(() => {
    getSubscriptionProducts().then(({ data }) => {
      setPlansLoading(false)
      const newsletter = getProductByType(data.subscriptionProducts.data, StripeProductEnum.Newsletter)
      const findAPart = getProductByType(data.subscriptionProducts.data, StripeProductEnum.FindAPart)

      setNewsletterPlans(
        newsletter && newsletter.plans
          ? newsletter.plans
              .filter(item => item.id.includes('public-') && item.active === true)
              .map(p => ({ id: p.id, name: p.nickname }))
          : [],
      )
      setFapPlans(
        findAPart && findAPart.plans
          ? findAPart.plans
              .filter(item => item.id.includes('public-') && item.active === true)
              .map(p => ({ id: p.id, name: p.nickname }))
          : [],
      )
    })
  }, [])

  return (
    <Create {...props}>
      <SimpleForm toolbar={<UserCreateToolbar />}>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="email" />
        <TextInput source="username" />
        <SelectInput
          source="role"
          choices={[
            { id: 'CurvoAdmin', name: 'CurvoAdmin' },
            ...(permissions === Role.DataEnrichmentAdmin
              ? [{ id: 'DataEnrichmentAdmin', name: 'DataEnrichmentAdmin' }]
              : []),
          ]}
        />
        <FormDataConsumer<{ role?: string }>>
          {({ formData, ...rest }) =>
            !formData.role && (
              <SelectInput isLoading={plansLoading} source="newsletter" choices={newsletterPlans} {...rest} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer<{ role?: string }>>
          {({ formData, ...rest }) =>
            !formData.role && <SelectInput isLoading={plansLoading} source="fap" choices={fapPlans} {...rest} />
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}
