"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseError = void 0;
const CurvoError_1 = require("./CurvoError");
const wrapError = (error) => new CurvoError_1.CurvoError('Error', 0, error);
const notifyError = (callback) => (error) => {
    if (callback) {
        callback(error);
        return;
    }
    throw error;
};
const parseError = (document, error, callback) => {
    if (!error) {
        return;
    }
    let location = '';
    try {
        const operation = document.definitions.find(i => i.kind === 'OperationDefinition');
        location = operation && `${operation.operation} ${operation.name ? operation.name.value : ''}`;
    }
    catch (_) {
        // Do nothing
    }
    const notify = notifyError(callback);
    const apollo = error;
    if (apollo) {
        const { graphQLErrors, networkError, message } = apollo;
        if (networkError) {
            const parse = JSON.parse(JSON.stringify(networkError));
            if (parse.statusCode) {
                const code = parse.statusCode || 0;
                const msg = parse.result.errors.map((e) => e.message).join('-');
                notify(new CurvoError_1.CurvoError(msg, code, new Error(msg), location));
            }
            else if (networkError.stack) {
                const splitError = networkError.stack.split(' at ');
                if (splitError.length > 0) {
                    notify(new CurvoError_1.CurvoError(message, 0, new Error(splitError[0].trim()), location));
                }
                else {
                    notify(new CurvoError_1.CurvoError(message, 0, new Error(networkError.stack), location));
                }
            }
            else {
                notify(new CurvoError_1.CurvoError(message, 0, new Error(networkError.message), location));
            }
        }
        else if (graphQLErrors && graphQLErrors.length > 0) {
            const msg = graphQLErrors.map((e) => e.message).join('-');
            notify(new CurvoError_1.CurvoError(msg, 500, new Error(msg), location));
        }
        else {
            notify(wrapError(apollo));
        }
        return;
    }
    notify(wrapError(error));
};
exports.parseError = parseError;
