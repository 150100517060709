"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLazyQueryMasterQueuedLink = exports.useGetStudyLinkMasterConflicts = exports.bulkRestoreOriginalFieldsStudyLinkMutation = exports.findAndReplaceStudyLinkMutation = exports.bulkMoveToTwinMatchMutation = exports.bulkUpdateBamfStudyLinksToHIMatchesMutation = exports.bulkUpdateStudyLinksMutation = exports.rematchStudyLinksMutation = exports.StudyLinkFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.StudyLinkFragment = (0, client_1.gql) `
  fragment StudyLinkFragment on StudyLinkType {
    id
    curvoId

    bamfStanId
    matchStanId
    stanManufacturerId
    stanPartNumber
    stanPartDescription
    stanManufacturer

    bamfGudidId
    matchGudidId
    gudidCompanyName
    gudidManufacturer
    gudidCatalogNumber
    gudidDescription
    gudidVersionModelNumber

    bamfMedId
    matchMedId
    medManufacturer
    medSku
    medMaterialCode
    medDescription

    vendor
    manufacturer
    mfgitem
    venitem
    description
    facitem
    uom
    uomconversion

    qtypurchase
    asp
    totalSpend

    isStanApproved
    isMedApproved
    isGudidApproved

    aiStanOverwrite
    aiMedOverwrite
    aiGudidOverwrite

    manufacturerStanType
    manufacturerMedType
    manufacturerGudidType

    blacklistVendor
    blacklistManufacturer
    blacklistVenitem
    blacklistMfgitem

    ignoreVendor
    ignoreManufacturer

    stanState
    medState
    gudidState

    hide
    stanSimilarity
    medSimilarity
    gudidSimilarity
  }
`;
const REMATCH_STUDY_LINKS = (0, client_1.gql) `
  mutation rematchStudyLinks($input: RematchStudyLinksInput!) {
    rematchStudyLinks(input: $input) {
      ...StudyLinkFragment
      tsId
      medMatch
      gudidMatch
      stanMatch
      gicId
      gicName
      materialId
      materialName
      typeOneId
      typeOneName
      typeTwoId
      typeTwoName
      segmentId
      segmentName

      matchCategory
    }
  }
  ${exports.StudyLinkFragment}
`;
exports.rematchStudyLinksMutation = (0, utils_1.mutateFunction)({ mutation: REMATCH_STUDY_LINKS });
const BULK_UPDATE_STUDY_LINKS = (0, client_1.gql) `
  mutation bulkUpdateStudyLinks($input: BulkUpdateStudyLinkInput!) {
    bulkUpdateStudyLinks(input: $input) {
      ...StudyLinkFragment
      tsId

      medMatch
      gudidMatch
      stanMatch

      matchCategory

      stanSimilarity
      medSimilarity
      gudidSimilarity

      gicId
      gicName
      materialId
      materialName
      typeOneId
      typeOneName
      typeTwoId
      typeTwoName
      segmentId
      segmentName
    }
  }
  ${exports.StudyLinkFragment}
`;
exports.bulkUpdateStudyLinksMutation = (0, utils_1.mutateFunction)({
    mutation: BULK_UPDATE_STUDY_LINKS,
});
const BULK_BAMF_TO_HI_MATCH = (0, client_1.gql) `
  mutation bulkUpdateBamfStudyLinksToHIMatches($input: BulkBamfToHIMatchingStudyLinkInput!) {
    bulkUpdateBamfStudyLinksToHIMatches(input: $input) {
      ...StudyLinkFragment
      tsId

      medMatch
      gudidMatch
      stanMatch

      matchCategory
    }
  }
  ${exports.StudyLinkFragment}
`;
exports.bulkUpdateBamfStudyLinksToHIMatchesMutation = (0, utils_1.mutateFunction)({ mutation: BULK_BAMF_TO_HI_MATCH });
const BULK_MOVE_TO_TWIN_MATCH = (0, client_1.gql) `
  mutation bulkMoveToTwinMatch($input: BulkMoveToTwinMatchStudyLinkInput!) {
    bulkMoveToTwinMatch(input: $input) {
      ...StudyLinkFragment
      tsId

      medMatch
      gudidMatch
      stanMatch
    }
  }
  ${exports.StudyLinkFragment}
`;
exports.bulkMoveToTwinMatchMutation = (0, utils_1.mutateFunction)({
    mutation: BULK_MOVE_TO_TWIN_MATCH,
});
const FIND_AND_REPLACE_STUDY_LINK = (0, client_1.gql) `
  mutation findAndReplaceStudyLink($input: StudyLinkFindAndReplaceInput!) {
    findAndReplaceStudyLink(input: $input)
  }
`;
exports.findAndReplaceStudyLinkMutation = (0, utils_1.mutateFunction)({
    mutation: FIND_AND_REPLACE_STUDY_LINK,
});
const BULK_RESTORE_ORIGINAL_FIELDS_STUDY_LINK = (0, client_1.gql) `
  mutation bulkRestoreOriginalFieldsStudyLink($input: BulkRestoreOriginalFieldsStudyLinkInput!) {
    bulkRestoreOriginalFieldsStudyLink(input: $input) {
      tsId
      ...StudyLinkFragment
    }
  }
  ${exports.StudyLinkFragment}
`;
exports.bulkRestoreOriginalFieldsStudyLinkMutation = (0, utils_1.mutateFunction)({
    mutation: BULK_RESTORE_ORIGINAL_FIELDS_STUDY_LINK,
});
const STUDY_LINK_MASTER_CONFLICTS = (0, client_1.gql) `
  query studyLinkConflicts($studyId: Int!, $tsId: String!, $catalog: StudyLinkCatalogEnum!) {
    studyLinkConflicts(studyId: $studyId, tsId: $tsId, catalog: $catalog) {
      tsId
      ...StudyLinkFragment
    }
  }
  ${exports.StudyLinkFragment}
`;
const useGetStudyLinkMasterConflicts = (options) => (0, utils_1.useQueryFunction)(STUDY_LINK_MASTER_CONFLICTS, options);
exports.useGetStudyLinkMasterConflicts = useGetStudyLinkMasterConflicts;
const MASTER_QUEUED_LINK = (0, client_1.gql) `
  query masterStudyLinks(
    $selectedFields: [String!]
    $startRow: Int!
    $endRow: Int!
    $sorting: [SortModel!]!
    $filter: [FilterModel!]!
  ) {
    masterStudyLinks(
      selectedFields: $selectedFields
      startRow: $startRow
      endRow: $endRow
      sorting: $sorting
      filter: $filter
    ) {
      edges {
        node {
          ...StudyLinkFragment
          ignoreRow
        }
      }
      metadata {
        total
      }
    }
  }
  ${exports.StudyLinkFragment}
`;
const useLazyQueryMasterQueuedLink = (options) => (0, utils_1.useLazyQueryFunction)(MASTER_QUEUED_LINK, options);
exports.useLazyQueryMasterQueuedLink = useLazyQueryMasterQueuedLink;
