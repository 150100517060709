"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstructsData = exports.CONSTRUCT_QUERY = exports.ConstructFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.ConstructFragment = (0, client_1.gql) `
  fragment ConstructFragment on GradingConstructType {
    id
    conId
    conName
    congrp
    proctype
    gicSelect
    constructExpression
    convertedConstruct
    masterGroup
    isActive
  }
`;
exports.CONSTRUCT_QUERY = (0, client_1.gql) `
  query constructQuery {
    gradingConstructs {
      ...ConstructFragment
    }
  }
  ${exports.ConstructFragment}
`;
exports.ConstructsData = (0, utils_1.buildQuery)({
    query: exports.CONSTRUCT_QUERY,
});
