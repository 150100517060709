import React from 'react'
import { Show, SimpleShowLayout, TextField, NumberField } from 'react-admin'

export const TagShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <NumberField source="newsletterCount" />
    </SimpleShowLayout>
  </Show>
)
