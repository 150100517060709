"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEW_PASSWORD_REQUIRED = void 0;
const tslib_1 = require("tslib");
const AWS = tslib_1.__importStar(require("amazon-cognito-identity-js"));
exports.NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
class Auth {
    constructor(poolData) {
        this.handleLocalStorageLoadUser = (onSuccess, onFailure) => {
            const user = new AWS.CognitoUserPool(this.poolData).getCurrentUser();
            if (user) {
                onSuccess(user);
                return;
            }
            onFailure(new Error('not_existed'));
        };
        this.loadUser = () => new Promise((resolve, reject) => {
            if (this.cognitoUser) {
                resolve(this.cognitoUser);
                return;
            }
            const onSuccess = user => {
                this.cognitoUser = user;
                resolve(user);
            };
            const onFailure = err => {
                this.cognitoUser = undefined;
                reject(err);
            };
            this.handleLocalStorageLoadUser(onSuccess, onFailure);
        });
        this.getSession = async () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const user = await this.loadUser();
                    if (user) {
                        user.getSession((err, session) => {
                            if (err) {
                                reject(err);
                            }
                            else if (!session.isValid()) {
                                user.refreshSession(session.getRefreshToken(), (refErr, newSession) => {
                                    if (refErr) {
                                        reject(refErr);
                                    }
                                    else {
                                        resolve(newSession);
                                    }
                                });
                            }
                            else {
                                resolve(session);
                            }
                        });
                    }
                    else {
                        throw new Error('Not login');
                    }
                }
                catch (err) {
                    reject(err);
                }
            });
        };
        this.signIn = async (username, password, newPassword) => {
            this.signOut();
            return new Promise((resolve, reject) => {
                const userPool = new AWS.CognitoUserPool(this.poolData);
                const authenDetail = new AWS.AuthenticationDetails({
                    Username: username,
                    Password: password,
                });
                const user = new AWS.CognitoUser({
                    Username: username,
                    Pool: userPool,
                });
                const defaultCallback = {
                    onSuccess: session => {
                        this.cognitoUser = user;
                        resolve(session);
                    },
                    onFailure: err => {
                        reject(err);
                    },
                };
                user.authenticateUser(authenDetail, {
                    ...defaultCallback,
                    newPasswordRequired() {
                        if (newPassword) {
                            user.completeNewPasswordChallenge(newPassword, null, this);
                            return;
                        }
                        reject(new Error(exports.NEW_PASSWORD_REQUIRED));
                    },
                });
            });
        };
        this.signOut = () => {
            if (this.cognitoUser) {
                this.cognitoUser.signOut();
                this.cognitoUser = undefined;
            }
        };
        this.forgotPassword = (username) => {
            this.signOut();
            return new Promise((resolve, reject) => {
                const userPool = new AWS.CognitoUserPool(this.poolData);
                const user = new AWS.CognitoUser({
                    Username: username,
                    Pool: userPool,
                });
                user.forgotPassword({
                    onSuccess: _data => {
                        resolve();
                    },
                    onFailure: err => {
                        reject(err);
                    },
                });
            });
        };
        this.changePassword = (username, oldPassword, newPassword) => {
            return new Promise((resolve, reject) => {
                const userPool = new AWS.CognitoUserPool(this.poolData);
                const authenDetail = new AWS.AuthenticationDetails({
                    Username: username,
                    Password: oldPassword,
                });
                const user = new AWS.CognitoUser({
                    Username: username,
                    Pool: userPool,
                });
                user.authenticateUser(authenDetail, {
                    onSuccess: _session => {
                        user.changePassword(oldPassword, newPassword, error => {
                            if (error) {
                                reject(error);
                            }
                            resolve();
                        });
                    },
                    onFailure: err => {
                        reject(err);
                    },
                });
            });
        };
        this.confirmPassword = (username, verificationCode, newPassword) => {
            const userPool = new AWS.CognitoUserPool(this.poolData);
            const user = new AWS.CognitoUser({
                Username: username,
                Pool: userPool,
            });
            return new Promise((resolve, reject) => {
                user.confirmPassword(verificationCode, newPassword, {
                    onSuccess: () => {
                        resolve();
                    },
                    onFailure: () => {
                        reject();
                    },
                });
            });
        };
        this.resendConfirmationCode = username => new Promise((resolve, reject) => {
            const userPool = new AWS.CognitoUserPool(this.poolData);
            const user = new AWS.CognitoUser({
                Username: username,
                Pool: userPool,
            });
            user.resendConfirmationCode((err, success) => {
                if (err) {
                    reject(err);
                    return;
                }
                resolve(success);
            });
        });
        this.confirmUser = (username, activationCode) => new Promise((resolve, reject) => {
            const userPool = new AWS.CognitoUserPool(this.poolData);
            const user = new AWS.CognitoUser({ Username: username, Pool: userPool });
            user.confirmRegistration(activationCode, true, (err, result) => {
                if (err) {
                    reject(err);
                    return;
                }
                resolve(result);
            });
        });
        this.poolData = poolData;
    }
}
exports.default = Auth;
