"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GicsWithImagesData = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const gic_1 = require("./gic");
const pageInfo_1 = require("./pageInfo");
const GET_GICS_WITH_IMAGES_QUERY = (0, client_1.gql) `
  query getAllGicWithImages($before: String, $after: String, $first: Int, $last: Int, $skip: Int) {
    getAllGicWithImages(before: $before, after: $after, first: $first, last: $last, skip: $skip) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...GicFragment
        }
        cursor
      }
    }
  }
  ${gic_1.GicFragment}
  ${pageInfo_1.PageInfoFragment}
`;
exports.GicsWithImagesData = (0, utils_1.buildQuery)({
    query: GET_GICS_WITH_IMAGES_QUERY,
    fetchMoreOpts: ({ variables, data }) => {
        const pageInfo = data && data.getAllGicWithImages && data.getAllGicWithImages.pageInfo;
        if (!pageInfo || !pageInfo.hasNextPage) {
            return undefined;
        }
        return {
            variables: { first: variables === null || variables === void 0 ? void 0 : variables.first, after: pageInfo.endCursor },
            updateQuery: (p, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.getAllGicWithImages) {
                    return p;
                }
                const pEdges = p.getAllGicWithImages.edges || [];
                const nEdges = fetchMoreResult.getAllGicWithImages.edges || [];
                return {
                    getAllGicWithImages: {
                        ...p.getAllGicWithImages,
                        edges: [...pEdges, ...nEdges],
                        pageInfo: fetchMoreResult.getAllGicWithImages.pageInfo,
                    },
                };
            },
        };
    },
});
