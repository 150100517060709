"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminSetUserTemporaryPassword = exports.ADMIN_SET_USER_TEMPORARY_PASSWORD_MUTATION = exports.adminConfirmUser = exports.ADMIN_CONFIRM_USER_MUTATION = exports.useCurvoUsersQuery = exports.getDBUsernameQuery = exports.getUsernameByEmail = exports.updateStripeMeta = exports.activateFirstTimeUser = exports.updateChildUserInfo = exports.inviteChildUser = exports.deleteChildUser = exports.confirmSignUpUser = exports.CONFIRM_SIGN_UP_USER_MUTATION = exports.resendSignUpCode = exports.signUp = exports.ChildUsersData = exports.UsersData = exports.legacySignIn = exports.UserData = exports.UserFragment = void 0;
const client_1 = require("@apollo/client");
const helpers_1 = require("../helpers");
const utils_1 = require("../helpers/utils");
exports.UserFragment = (0, client_1.gql) `
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    isActive
    username
    company
    phoneNumber
    userType
    expirationDate
    fap
    newsletter
  }
`;
// Fetch User
const USER_QUERY = (0, client_1.gql) `
  query User($id: ID!) {
    User(id: $id) {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.UserData = (0, utils_1.buildQuery)({ query: USER_QUERY });
// Legacy User Sign In
const LEGACY_SIGN_IN_MUTATION = (0, client_1.gql) `
  mutation legacySignIn($username: String!, $password: String!) {
    legacySignIn(username: $username, password: $password)
  }
`;
exports.legacySignIn = (0, utils_1.mutateFunction)({
    mutation: LEGACY_SIGN_IN_MUTATION,
});
// Fetch All Users
const USERS_QUERY = (0, client_1.gql) `
  query allUsers($first: Int, $filter: UserQueryFilterInput) {
    allUsers(first: $first, filter: $filter) {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.UsersData = (0, utils_1.buildQuery)({
    query: USERS_QUERY,
});
const CHILD_USERS_QUERY = (0, client_1.gql) `
  query childUsers {
    childUsers {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.ChildUsersData = (0, utils_1.buildQuery)({
    query: CHILD_USERS_QUERY,
});
// Sign Up
const SIGN_UP_MUTATION = (0, client_1.gql) `
  mutation signUp($firstName: String!, $lastName: String!, $username: String!, $email: String!, $password: String!) {
    signUp(firstName: $firstName, lastName: $lastName, username: $username, email: $email, password: $password) {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.signUp = (0, utils_1.mutateFunction)({
    mutation: SIGN_UP_MUTATION,
});
// Resend Sign Up Code
const RESEND_SIGN_UP_CODE_MUTATION = (0, client_1.gql) `
  mutation resendSignUpCode($username: String!) {
    resendSignUpCode(username: $username) {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.resendSignUpCode = (0, utils_1.mutateFunction)({ mutation: RESEND_SIGN_UP_CODE_MUTATION });
// Confirm Sign Up User
exports.CONFIRM_SIGN_UP_USER_MUTATION = (0, client_1.gql) `
  mutation confirmSignUpUser($code: String!, $username: String!) {
    confirmSignUpUser(code: $code, username: $username) {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.confirmSignUpUser = (0, utils_1.mutateFunction)({ mutation: exports.CONFIRM_SIGN_UP_USER_MUTATION });
// Delete child user
const DELETE_CHILD_USER_MUTATION = (0, client_1.gql) `
  mutation deleteChildUser($id: ID!) {
    deleteChildUser(id: $id) {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.deleteChildUser = (0, utils_1.mutateFunction)({
    mutation: DELETE_CHILD_USER_MUTATION,
    refetchQueries: [{ query: CHILD_USERS_QUERY }],
});
// Invite child user
const INVITE_CHILD_USER_MUTATION = (0, client_1.gql) `
  mutation inviteUser(
    $email: String!
    $username: String!
    $firstName: String
    $lastName: String
    $company: String
    $phoneNumber: String
  ) {
    inviteUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      username: $username
      company: $company
      phoneNumber: $phoneNumber
    ) {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.inviteChildUser = (0, utils_1.mutateFunction)({
    mutation: INVITE_CHILD_USER_MUTATION,
    refetchQueries: [{ query: CHILD_USERS_QUERY }],
});
// Update child user info
const PARENT_UPDATE_CHILD_USER_INFO_MUTATION = (0, client_1.gql) `
  mutation parentUpdateChildUserInfo(
    $id: ID!
    $firstName: String
    $lastName: String
    $company: String
    $phoneNumber: String
    $isActive: Boolean
  ) {
    parentUpdateChildUserInfo(
      id: $id
      firstName: $firstName
      lastName: $lastName
      company: $company
      phoneNumber: $phoneNumber
      isActive: $isActive
    ) {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.updateChildUserInfo = (0, utils_1.mutateFunction)({
    mutation: PARENT_UPDATE_CHILD_USER_INFO_MUTATION,
    refetchQueries: [{ query: CHILD_USERS_QUERY }],
});
// First-time activate user
const FIRST_TIME_ACTIVATE_USER = (0, client_1.gql) `
  mutation firstTimeActivateChildUser {
    firstTimeActivateChildUser {
      ...UserFragment
    }
  }
  ${exports.UserFragment}
`;
exports.activateFirstTimeUser = (0, utils_1.mutateFunction)({
    mutation: FIRST_TIME_ACTIVATE_USER,
});
const UPDATE_STRIPE_META = (0, client_1.gql) `
  mutation updateStripeMeta {
    updateStripeMeta
  }
`;
exports.updateStripeMeta = (0, utils_1.mutateFunction)({
    mutation: UPDATE_STRIPE_META,
});
const USERNAME_BY_EMAIL = (0, client_1.gql) `
  query userNameFromEmail($email: String!) {
    userNameFromEmail(email: $email)
  }
`;
const getUsernameByEmail = async (email) => {
    const result = await helpers_1.Client.getClient().query({
        query: USERNAME_BY_EMAIL,
        variables: { email },
    });
    return result.data;
};
exports.getUsernameByEmail = getUsernameByEmail;
// Fetch username
const USERNAME_QUERY = (0, client_1.gql) `
  query Username($username: String!) {
    usernameFromDB(username: $username)
  }
`;
const getDBUsernameQuery = async (username) => {
    const result = await helpers_1.Client.getClient().query({
        query: USERNAME_QUERY,
        variables: { username },
    });
    return result.data;
};
exports.getDBUsernameQuery = getDBUsernameQuery;
const CURVO_USERS = (0, client_1.gql) `
  query curvoUsers {
    allCurvoUsers {
      ...UserFragment
      role
    }
  }
  ${exports.UserFragment}
`;
const useCurvoUsersQuery = (options) => {
    return (0, utils_1.useQueryFunction)(CURVO_USERS, options);
};
exports.useCurvoUsersQuery = useCurvoUsersQuery;
exports.ADMIN_CONFIRM_USER_MUTATION = (0, client_1.gql) `
  mutation activateUser($username: String!) {
    activateUser(username: $username)
  }
`;
exports.adminConfirmUser = (0, utils_1.mutateFunction)({ mutation: exports.ADMIN_CONFIRM_USER_MUTATION });
exports.ADMIN_SET_USER_TEMPORARY_PASSWORD_MUTATION = (0, client_1.gql) `
  mutation setUserTemporaryPassword($username: String!) {
    setUserTemporaryPassword(username: $username)
  }
`;
exports.adminSetUserTemporaryPassword = (0, utils_1.mutateFunction)({ mutation: exports.ADMIN_SET_USER_TEMPORARY_PASSWORD_MUTATION });
