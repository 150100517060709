"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteGicImageMutation = exports.createGicImageMutation = exports.useGicImagesData = exports.GIC_IMAGES = exports.useGicsData = exports.deleteGicMutation = exports.GIC_DELETE_MUTATION = exports.createGicMutation = exports.GIC_CREATE_MUTATION = exports.updateGicMutation = exports.GIC_UPDATE_MUTATION = exports.GicsData = exports.GICS_QUERY = exports.FULL_GICS_QUERY = exports.GicImageFragment = exports.GicFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const pageInfo_1 = require("./pageInfo");
exports.GicFragment = (0, client_1.gql) `
  fragment GicFragment on Gic {
    id
    name
    includes
    excludes
    inputName
    sizeOneDescription
    sizeTwoDescription
    sizeThreeDescription
    imageUrl
  }
`;
exports.GicImageFragment = (0, client_1.gql) `
  fragment GicImageFragment on GicImage {
    id
    imageUrl
    type
    gicId
    gicTypeOneId
    gicTypeTwoId
  }
`;
exports.FULL_GICS_QUERY = (0, client_1.gql) `
  query Gic(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $searchText: String
    $manufacturerId: String
    $orderBy: GicsOrderByEnum
    $direction: SortOrderEnum
  ) {
    gics(
      before: $before
      after: $after
      first: $first
      last: $last
      searchText: $searchText
      skip: $skip
      manufacturerId: $manufacturerId
      orderBy: $orderBy
      direction: $direction
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...GicFragment
          allowedGicTypeOnes {
            gicTypeOne {
              id
              name
              includes
            }
            allowedGicTypeTwos {
              id
              name
              includes
            }
          }
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.GicFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.GICS_QUERY = (0, client_1.gql) `
  query Gic(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $searchText: String
    $manufacturerId: String
    $orderBy: GicsOrderByEnum
    $direction: SortOrderEnum
  ) {
    gics(
      before: $before
      after: $after
      first: $first
      last: $last
      searchText: $searchText
      skip: $skip
      manufacturerId: $manufacturerId
      orderBy: $orderBy
      direction: $direction
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...GicFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.GicFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.GicsData = (0, utils_1.buildQuery)({
    query: exports.GICS_QUERY,
    fetchMoreOpts: ({ variables, data }) => {
        const pageInfo = data && data.gics && data.gics.pageInfo;
        if (!pageInfo || !pageInfo.hasNextPage) {
            return undefined;
        }
        return {
            variables: { first: variables === null || variables === void 0 ? void 0 : variables.first, after: pageInfo.endCursor, skip: variables === null || variables === void 0 ? void 0 : variables.skip },
            updateQuery: (p, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.gics) {
                    return p;
                }
                const pEdges = p.gics.edges || [];
                const nEdges = fetchMoreResult.gics.edges || [];
                return {
                    gics: {
                        ...p.gics,
                        edges: [...pEdges, ...nEdges],
                        pageInfo: fetchMoreResult.gics.pageInfo,
                    },
                };
            },
        };
    },
});
exports.GIC_UPDATE_MUTATION = (0, client_1.gql) `
  mutation UpdateGic($input: GicUpdateInput!) {
    updateGic(input: $input) {
      gic {
        ...GicFragment
      }
      allowedGicTypeOnesUpdated {
        updateType
        gicTypeOneId
      }
      allowedGicMaterialsUpdated {
        updateType
        gicMaterialId
      }
    }
  }
  ${exports.GicFragment}
`;
exports.updateGicMutation = (0, utils_1.mutateFunction)({
    mutation: exports.GIC_UPDATE_MUTATION,
});
exports.GIC_CREATE_MUTATION = (0, client_1.gql) `
  mutation CreateGIC($input: GicCreateInput!) {
    createGic(input: $input) {
      ...GicFragment
    }
  }
  ${exports.GicFragment}
`;
exports.createGicMutation = (0, utils_1.mutateFunction)({
    mutation: exports.GIC_CREATE_MUTATION,
});
exports.GIC_DELETE_MUTATION = (0, client_1.gql) `
  mutation deleteGIC($id: Int!) {
    deleteGic(id: $id) {
      ...GicFragment
    }
  }
  ${exports.GicFragment}
`;
exports.deleteGicMutation = (0, utils_1.mutateFunction)({
    mutation: exports.GIC_DELETE_MUTATION,
});
function useGicsData(options) {
    return (0, utils_1.useQueryFunction)(exports.GICS_QUERY, options);
}
exports.useGicsData = useGicsData;
exports.GIC_IMAGES = (0, client_1.gql) `
  query gicImages($id: Int!) {
    gicImages(id: $id) {
      ...GicImageFragment
    }
  }
  ${exports.GicImageFragment}
`;
function useGicImagesData(options) {
    return (0, utils_1.useQueryFunction)(exports.GIC_IMAGES, options);
}
exports.useGicImagesData = useGicImagesData;
const CREATE_GIC_IMAGE = (0, client_1.gql) `
  mutation createGicImage($input: CreateGicImageInput!) {
    createGicImage(input: $input) {
      ...GicImageFragment
    }
  }
  ${exports.GicImageFragment}
`;
exports.createGicImageMutation = (0, utils_1.mutateFunction)({ mutation: CREATE_GIC_IMAGE });
const DELETE_GIC_IMAGE = (0, client_1.gql) `
  mutation deleteGicImage($id: ID!) {
    deleteGicImage(id: $id) {
      ...GicImageFragment
    }
  }
  ${exports.GicImageFragment}
`;
exports.deleteGicImageMutation = (0, utils_1.mutateFunction)({ mutation: DELETE_GIC_IMAGE });
