import { Newsletter } from '@curvo/apollo'
import React from 'react'
import {
  ChipField,
  Datagrid,
  FunctionField,
  List,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin'

export const NewsletterList = props => (
  <List {...props} sort={{ field: 'issue', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <FunctionField
        label="Published Date"
        sortBy="publishYear"
        render={(item: Newsletter) =>
          item.publishMonth && item.publishYear && `${item.publishMonth}/${item.publishYear}`
        }
      />
      <TextField source="issue" />
      <TextField source="section" />
      <FunctionField
        label="Download Url"
        render={(item: Newsletter) => {
          return (
            <a target="_blank" href={item.downloadUrl!} onClick={e => e.stopPropagation()}>
              Download Link
            </a>
          )
        }}
      />
      <ReferenceArrayField label="Tags" reference="Tag" source="tagsIds" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField label="Category" reference="Category" source="category.id" allowEmpty={true} sortable={false}>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)
