"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteProductLineMutation = exports.createProductLineMutation = exports.updateProductLineMutation = exports.ProductLinesData = exports.PRODUCT_LINES_QUERY = exports.ProductLineFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const manufacturer_1 = require("./manufacturer");
const pageInfo_1 = require("./pageInfo");
exports.ProductLineFragment = (0, client_1.gql) `
  fragment ProductLineFragment on ProductLine {
    id
    name
    isInactive
  }
`;
exports.PRODUCT_LINES_QUERY = (0, client_1.gql) `
  query ProductLines(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $searchText: String
    $manufacturerId: String
    $isInactive: Boolean
  ) {
    productLines(
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      searchText: $searchText
      manufacturerId: $manufacturerId
      isInactive: $isInactive
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...ProductLineFragment
          manufacturer {
            ...ManufacturerFragment
          }
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.ProductLineFragment}
  ${manufacturer_1.ManufacturerFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.ProductLinesData = (0, utils_1.buildQuery)({
    query: exports.PRODUCT_LINES_QUERY,
    fetchMoreOpts: ({ variables, data }) => {
        const pageInfo = data && data.productLines && data.productLines.pageInfo;
        if (!pageInfo || !pageInfo.hasNextPage) {
            return undefined;
        }
        return {
            variables: { first: variables === null || variables === void 0 ? void 0 : variables.first, after: pageInfo.endCursor },
            updateQuery: (p, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.productLines) {
                    return p;
                }
                const pEdges = p.productLines.edges || [];
                const nEdges = fetchMoreResult.productLines.edges || [];
                return {
                    productLines: {
                        ...p.productLines,
                        edges: [...pEdges, ...nEdges],
                        pageInfo: fetchMoreResult.productLines.pageInfo,
                    },
                };
            },
        };
    },
});
const PRODUCT_LINE_UPDATE = (0, client_1.gql) `
  mutation updateProductLine($input: ProductLineUpdateInput!) {
    updateProductLine(input: $input) {
      ...ProductLineFragment
      manufacturer {
        ...ManufacturerFragment
      }
    }
  }
  ${exports.ProductLineFragment}
  ${manufacturer_1.ManufacturerFragment}
`;
exports.updateProductLineMutation = (0, utils_1.mutateFunction)({ mutation: PRODUCT_LINE_UPDATE });
const PRODUCT_LINE_CREATE = (0, client_1.gql) `
  mutation addProductLine($input: ProductLineCreateInput!) {
    addProductLine(input: $input) {
      ...ProductLineFragment
      manufacturer {
        ...ManufacturerFragment
      }
    }
  }
  ${exports.ProductLineFragment}
  ${manufacturer_1.ManufacturerFragment}
`;
exports.createProductLineMutation = (0, utils_1.mutateFunction)({ mutation: PRODUCT_LINE_CREATE });
const PRODUCT_LINE_DELETE = (0, client_1.gql) `
  mutation deleteProductLine($id: ID!) {
    deleteProductLine(id: $id) {
      ...ProductLineFragment
    }
  }
  ${exports.ProductLineFragment}
`;
exports.deleteProductLineMutation = (0, utils_1.mutateFunction)({ mutation: PRODUCT_LINE_DELETE });
