import React from 'react'
import { Show, SimpleShowLayout, TextField } from 'react-admin'

export const CategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
    </SimpleShowLayout>
  </Show>
)
