import { User } from '@curvo/apollo'
import {
  CreateButton,
  Datagrid,
  EmailField,
  FunctionField,
  Pagination,
  ReferenceArrayField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin'

export const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="isActive" label="Status" />
      <TextField source="username" />
      <TextField source="company" />
      <TextField source="phoneNumber" />
      <FunctionField
        label="Subscriptions"
        render={(user: User) =>
          user.paymentInfo &&
          user.paymentInfo.subscriptions &&
          user.paymentInfo.subscriptions.data.map(
            item =>
              item.plan && (
                <div key={item.id}>
                  {`${item.plan!.id.match(/news/) ? 'Newsletter' : 'Find A Part'} (${item.plan.nickname})`}
                </div>
              ),
          )
        }
      />
      <TextField source="role" />
      <ReferenceArrayField
        source="childUsersIds"
        reference="ChildUser"
        label="Sub-Accounts"
        perPage={10}
        pagination={<Pagination />}>
        <CreateRelatedChildUserButton />
        <Datagrid bulkActionButtons={false}>
          <TextField source="username" sortable={false} />
          <EmailField source="email" sortable={false} />
          <TextField source="firstName" sortable={false} />
          <TextField source="lastName" sortable={false} />
          <TextField source="isActive" sortable={false} label="Status" />
          <TextField source="company" sortable={false} />
          <TextField source="phoneNumber" sortable={false} />
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
)

const CreateRelatedChildUserButton = () => {
  const record = useRecordContext()
  return (
    <CreateButton
      resource="ChildUser"
      state={{ record: { parent: record.username, parentId: record.id } }}
      label="Create Child User"
    />
  )
}
