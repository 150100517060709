import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'

export const TagList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" sortable={false} />
      <TextField source="description" sortable={false} />
    </Datagrid>
  </List>
)
