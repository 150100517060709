"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomersQuery = exports.useBasketLookupNamesQuery = exports.deleteBasketLookupsMutation = exports.createBasketLookupMutation = exports.updateBasketLookupsMutation = exports.useLazyBasketLookupsQuery = exports.BasketLookupFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.BasketLookupFragment = (0, client_1.gql) `
  fragment BasketLookupFragment on BasketLookupType {
    id
    basketName
    customer {
      id
      name
    }
    tsId
    gudidId
    gudidBrand
    stanId
    gmdnName
    gicTypeOneTypeTwo
    manufacturer
    vendor
    negative
    commodityId
    unspscClassTitle
  }
`;
const BASKET_LOOKUP_LIST = (0, client_1.gql) `
  query basketLookups(
    $selectedFields: [String!]
    $startRow: Int!
    $endRow: Int!
    $sorting: [SortModel!]!
    $filter: [FilterModel!]!
  ) {
    basketLookups(
      selectedFields: $selectedFields
      startRow: $startRow
      endRow: $endRow
      sorting: $sorting
      filter: $filter
    ) {
      edges {
        node {
          ...BasketLookupFragment
        }
      }
      metadata {
        total
      }
    }
  }
  ${exports.BasketLookupFragment}
`;
const useLazyBasketLookupsQuery = (options) => (0, utils_1.useLazyQueryFunction)(BASKET_LOOKUP_LIST, options);
exports.useLazyBasketLookupsQuery = useLazyBasketLookupsQuery;
const BASKET_LOOKUP_UPDATE = (0, client_1.gql) `
  mutation updateBasketLookup($ids: [String!]!, $input: BasketLookupInput!) {
    updateBasketLookup(ids: $ids, input: $input) {
      ...BasketLookupFragment
    }
  }
  ${exports.BasketLookupFragment}
`;
exports.updateBasketLookupsMutation = (0, utils_1.mutateFunction)({ mutation: BASKET_LOOKUP_UPDATE });
const BASKET_LOOKUP_CREATE = (0, client_1.gql) `
  mutation createBasketLookup($input: BasketLookupInput!) {
    createBasketLookup(input: $input) {
      ...BasketLookupFragment
    }
  }
  ${exports.BasketLookupFragment}
`;
exports.createBasketLookupMutation = (0, utils_1.mutateFunction)({ mutation: BASKET_LOOKUP_CREATE });
const BASKET_LOOKUP_DELETE = (0, client_1.gql) `
  mutation removeBasketLookupsByIds($ids: [String!]!) {
    removeBasketLookupsByIds(ids: $ids)
  }
`;
exports.deleteBasketLookupsMutation = (0, utils_1.mutateFunction)({ mutation: BASKET_LOOKUP_DELETE });
const BASKET_LOOKUP_NAMES = (0, client_1.gql) `
  query basketLookupNames {
    basketLookupNames
  }
`;
const useBasketLookupNamesQuery = (options) => (0, utils_1.useQueryFunction)(BASKET_LOOKUP_NAMES, options);
exports.useBasketLookupNamesQuery = useBasketLookupNamesQuery;
const CUSTOMER_LIST = (0, client_1.gql) `
  query customers($searchText: String) {
    customers(searchText: $searchText) {
      id
      name
    }
  }
`;
const useCustomersQuery = (options) => (0, utils_1.useQueryFunction)(CUSTOMER_LIST, options);
exports.useCustomersQuery = useCustomersQuery;
