"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchHistoryData = exports.SEARCH_HISTORY_QUERY = exports.SearchHistoryFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const user_1 = require("./user");
exports.SearchHistoryFragment = (0, client_1.gql) `
  fragment SearchHistoryFragment on Search {
    id
    isPaid
    user {
      ...UserFragment
    }
  }
  ${user_1.UserFragment}
`;
exports.SEARCH_HISTORY_QUERY = (0, client_1.gql) `
  query SearchHistory {
    searchHistory {
      ...SearchHistoryFragment
    }
  }
  ${exports.SearchHistoryFragment}
`;
exports.SearchHistoryData = (0, utils_1.buildQuery)({
    query: exports.SEARCH_HISTORY_QUERY,
});
