"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchMasterDataUnspsc = exports.useSearchMasterDataGudidBrand = exports.useSearchMasterDataGmdnName = exports.useSearchMasterDataParts = exports.MasterDataSearchFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.MasterDataSearchFragment = (0, client_1.gql) `
  fragment MasterDataSearchFragment on MasterDataType {
    id
    id
    catalogNumber
    companyName
    versionModelNumber
    sku
    materialCode
    description
    manufacturer
    type
    source
    vendorCurvo
    manufacturerCurvo
  }
`;
const MASTER_DATA_SEARCH = (0, client_1.gql) `
  query searchMasterData($input: MasterDataSearchInput!) {
    searchMasterData(input: $input) {
      ...MasterDataSearchFragment
    }
  }
  ${exports.MasterDataSearchFragment}
`;
const useSearchMasterDataParts = (basicOption) => {
    return (0, utils_1.useQueryFunction)(MASTER_DATA_SEARCH, basicOption);
};
exports.useSearchMasterDataParts = useSearchMasterDataParts;
const MASTER_DATA_GMDN_NAME_SEARCH = (0, client_1.gql) `
  query searchGmdnNames($input: SearchGmdnNameInput!) {
    searchGmdnNames(input: $input)
  }
`;
const useSearchMasterDataGmdnName = (basicOption) => {
    return (0, utils_1.useQueryFunction)(MASTER_DATA_GMDN_NAME_SEARCH, basicOption);
};
exports.useSearchMasterDataGmdnName = useSearchMasterDataGmdnName;
const MASTER_DATA_GUDID_BRAND_SEARCH = (0, client_1.gql) `
  query searchGudidBrands($input: SearchGudidBrandInput!) {
    searchGudidBrands(input: $input)
  }
`;
const useSearchMasterDataGudidBrand = (basicOption) => {
    return (0, utils_1.useQueryFunction)(MASTER_DATA_GUDID_BRAND_SEARCH, basicOption);
};
exports.useSearchMasterDataGudidBrand = useSearchMasterDataGudidBrand;
const MASTER_DATA_UNSPSC_SEARCH = (0, client_1.gql) `
  query searchMedUnspsc($input: SearchUnspscInput!) {
    searchMedUnspsc(input: $input)
  }
`;
const useSearchMasterDataUnspsc = (basicOption) => {
    return (0, utils_1.useQueryFunction)(MASTER_DATA_UNSPSC_SEARCH, basicOption);
};
exports.useSearchMasterDataUnspsc = useSearchMasterDataUnspsc;
