import { Role, getNewsletterUploadInfo, schema } from '@curvo/apollo'
import CategoryIcon from '@material-ui/icons/CollectionsBookmark'
import NewsletterIcon from '@material-ui/icons/LibraryBooks'
import TagIcon from '@material-ui/icons/LocalOffer'
import UserIcon from '@material-ui/icons/People'
import axios from 'axios'
import gql from 'graphql-tag'
import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple'
import React from 'react'
import { Admin, Resource, withLifecycleCallbacks } from 'react-admin'
import authProvider from './auth/authProvider'
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from './components/categories'
import { NewsletterCreate, NewsletterEdit, NewsletterList, NewsletterShow } from './components/newsletters'
import { TagCreate, TagEdit, TagList, TagShow } from './components/tags'
import { UserCreate, UserEdit, UserList, UserShow } from './components/users'
import { ChildUserCreate } from './components/users/ChildUserCreate'
import Client from './configs/Apollo'

const App = () => {
  const [dataProvider, setDataProvider] = React.useState<any>(null)

  React.useEffect(() => {
    buildGraphQLProvider({
      client: Client.getClient() as any,
      introspection: { schema: schema.data.__schema as any },
      buildQuery: introspection => (fetchType, resource, params) => {
        const builtQuery = buildQuery(introspection)(fetchType, resource, params)
        if (resource === 'User' && fetchType === 'GET_LIST') {
          return {
            ...builtQuery,
            query: gql`
              query allUsers(
                $before: String
                $after: String
                $first: Int = 10
                $last: Int
                $skip: Int
                $filter: UserQueryFilterInput
                $orderBy: String
              ) {
                items: allUsers(
                  filter: $filter
                  before: $before
                  after: $after
                  first: $first
                  last: $last
                  skip: $skip
                  orderBy: $orderBy
                ) {
                  id
                  firstName
                  lastName
                  email
                  username
                  company
                  phoneNumber
                  isActive
                  role
                  userType
                  addressLineOne
                  addressLineTwo
                  city
                  state
                  zip
                  wantHardCopy
                  expirationDate
                  fap
                  newsletter
                  lastLogin
                  __typename
                }
                total: _allUsersMeta(filter: $filter) {
                  count
                  __typename
                }
              }
            `,
          }
        }
        return builtQuery
      },
    }).then(graphQlDataProvider => {
      setDataProvider(() =>
        withLifecycleCallbacks(graphQlDataProvider, [
          {
            resource: 'Newsletter',
            beforeSave: async (params: any) => {
              const { data, errors } = await getNewsletterUploadInfo()

              if (errors) {
                console.error(errors[0])
                return
              }

              const info = data && data.generateNewsletterUploadInfo
              if (info) {
                await axios.put(info.uploadUrl, params.key.rawFile)
              }

              return {
                ...params,
                key: info.key,
              }
            },
          },
        ]),
      )
    })
  }, [])

  if (!dataProvider) {
    return <div>Loading...</div>
  }

  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      {(permissions: Role) => {
        return [
          permissions !== Role.User ? (
            [
              <Resource
                key="User"
                icon={UserIcon}
                name="User"
                list={UserList}
                create={UserCreate}
                show={UserShow}
                edit={UserEdit}
                recordRepresentation={u => u.username}
              />,
              <Resource
                key="Newsletter"
                name="Newsletter"
                icon={NewsletterIcon}
                list={NewsletterList}
                create={NewsletterCreate}
                show={NewsletterShow}
                edit={NewsletterEdit}
                recordRepresentation={u => u.name}
              />,
              <Resource
                key="Tag"
                name="Tag"
                icon={TagIcon}
                list={TagList}
                show={TagShow}
                edit={TagEdit}
                create={TagCreate}
              />,
              <Resource
                key="Category"
                name="Category"
                icon={CategoryIcon}
                create={CategoryCreate}
                list={CategoryList}
                show={CategoryShow}
                edit={CategoryEdit}
              />,
              <Resource name="ChildUser" key="ChildUser" create={ChildUserCreate} />,
            ]
          ) : (
            <div>Access Denied!</div>
          ),
        ]
      }}
    </Admin>
  )
}

export default App
