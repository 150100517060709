import React from 'react'
import {
  Create,
  FileInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { validatePublished } from './NewsletterEdit'

class NewsletterCreate extends React.Component<{}, { isUploading: boolean }> {
  constructor(props) {
    super(props)
  }

  required =
    (message = 'Required') =>
    value =>
      value ? undefined : message

  render() {
    return (
      <Create {...this.props}>
        <SimpleForm validate={validatePublished} redirect="list">
          <TextInput source="name" />
          <TextInput source="publishMonth" type="number" />
          <TextInput source="publishYear" type="number" />
          <TextInput source="issue" />
          <TextInput source="section" type="number" />
          <FileInput source="key" accept="application/pdf" />
          <ReferenceArrayInput label="Tags" resource="" source="tagsIds" reference="Tag">
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceInput label="Category" source="category.id" reference="Category">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput multiline source="description" />
        </SimpleForm>
      </Create>
    )
  }
}

export default NewsletterCreate
