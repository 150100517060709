"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reGradePurchaseOrderMutation = exports.bulkUpdatePurchaseOrdersMutation = exports.getPurchaseOrder = exports.PURCHASE_ORDER_BY_NUMBER = exports.purchaseOrdersMergeMutation = exports.purchaseOrderUpdateMutation = exports.usePurchaseOrdersData = exports.PurchaseOrdersData = exports.PURCHASE_ORDERS_QUERY = exports.PurchaseOrderPartFragment = exports.PurchaseOrderFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const helpers_1 = require("../helpers");
const construct_1 = require("./construct");
const part_1 = require("./part");
exports.PurchaseOrderFragment = (0, client_1.gql) `
  fragment PurchaseOrderFragment on PurchaseOrder {
    id
    number
    attributes
    totalValue
    segment
    surgeon
    procdate
    studyId
    unapprovedParts
    validationErrors
  }
`;
exports.PurchaseOrderPartFragment = (0, client_1.gql) `
  fragment PurchaseOrderPartFragment on PurchaseOrderPart {
    id
    part {
      ...PartFragment
    }
    extprice
    unitprice
  }
  ${part_1.PartFragment}
`;
exports.PURCHASE_ORDERS_QUERY = (0, client_1.gql) `
  query purchaseOrdersByStudyId($studyId: Int!) {
    purchaseOrdersByStudyId(studyId: $studyId) {
      ...PurchaseOrderFragment
      construct {
        ...ConstructFragment
      }
    }
  }
  ${exports.PurchaseOrderFragment}
  ${construct_1.ConstructFragment}
`;
exports.PurchaseOrdersData = (0, utils_1.buildQuery)({
    query: exports.PURCHASE_ORDERS_QUERY,
});
function usePurchaseOrdersData(options) {
    return (0, utils_1.useQueryFunction)(exports.PURCHASE_ORDERS_QUERY, options);
}
exports.usePurchaseOrdersData = usePurchaseOrdersData;
const PURCHASE_ORDER_UPDATE = (0, client_1.gql) `
  mutation PurchaseOrderUpdate($input: PurchaseOrderUpdateInput!) {
    updatePurchaseOrder(input: $input) {
      ...PurchaseOrderFragment
      construct {
        ...ConstructFragment
      }
    }
  }
  ${exports.PurchaseOrderFragment}
  ${construct_1.ConstructFragment}
`;
exports.purchaseOrderUpdateMutation = (0, utils_1.mutateFunction)({
    mutation: PURCHASE_ORDER_UPDATE,
});
const PURCHASE_ORDERS_MERGE = (0, client_1.gql) `
  mutation mergePurchaseOrders($input: PurchaseOrdersMergeInput!) {
    mergePurchaseOrders(input: $input) {
      ...PurchaseOrderFragment
      construct {
        ...ConstructFragment
      }
    }
  }
  ${exports.PurchaseOrderFragment}
  ${construct_1.ConstructFragment}
`;
exports.purchaseOrdersMergeMutation = (0, utils_1.mutateFunction)({
    mutation: PURCHASE_ORDERS_MERGE,
});
exports.PURCHASE_ORDER_BY_NUMBER = (0, client_1.gql) `
  query purchaseOrder($studyId: Int!, $ponum: String!) {
    purchaseOrder(studyId: $studyId, ponum: $ponum) {
      ...PurchaseOrderFragment
      construct {
        ...ConstructFragment
      }
    }
  }
  ${exports.PurchaseOrderFragment}
  ${construct_1.ConstructFragment}
`;
const getPurchaseOrder = (studyId, ponum) => {
    return helpers_1.Client.getClient().query({
        query: exports.PURCHASE_ORDER_BY_NUMBER,
        variables: { studyId, ponum },
    });
};
exports.getPurchaseOrder = getPurchaseOrder;
const BULK_UPDATE_PURCHASE_ORDER = (0, client_1.gql) `
  mutation bulkUpdatePurchaseOrders($input: PurchaseOrderBulkUpdateInput!) {
    bulkUpdatePurchaseOrders(input: $input) {
      ...PurchaseOrderFragment
      construct {
        ...ConstructFragment
      }
    }
  }
  ${exports.PurchaseOrderFragment}
  ${construct_1.ConstructFragment}
`;
exports.bulkUpdatePurchaseOrdersMutation = (0, utils_1.mutateFunction)({
    mutation: BULK_UPDATE_PURCHASE_ORDER,
});
const REGRADE_PURCHASE_ORDER = (0, client_1.gql) `
  mutation reGradePurchaseOrder($studyId: Int!, $ponum: String!) {
    reGradePurchaseOrder(studyId: $studyId, ponum: $ponum) {
      ...PurchaseOrderFragment
    }
  }
  ${exports.PurchaseOrderFragment}
`;
exports.reGradePurchaseOrderMutation = (0, utils_1.mutateFunction)({
    mutation: REGRADE_PURCHASE_ORDER,
});
