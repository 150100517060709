import { Client, Role } from '@curvo/apollo'
import Cognito from './Cognito'

Client.configClient({
  protocol: 'https',
  wsProtocol: 'wss',
  getToken: async () => {
    const session = await Cognito.getSession()
    return session.getAccessToken().getJwtToken()
  },
})

export const getRole = async (): Promise<Role> => {
  const session = await Cognito.getSession()
  const payload = session.getAccessToken().decodePayload()
  const groups = (payload && payload['cognito:groups']) || []
  if (groups.indexOf(Role.DataEnrichmentAdmin) !== -1) {
    return Role.DataEnrichmentAdmin
  }

  if (groups.indexOf(Role.CurvoAdmin) !== -1) {
    return Role.CurvoAdmin
  }

  return Role.User
}

export default Client
