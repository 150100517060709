"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubscriptionProducts = exports.SubscriptionProductsData = void 0;
const client_1 = require("@apollo/client");
const helpers_1 = require("../helpers");
const utils_1 = require("../helpers/utils");
const stripe_1 = require("./stripe");
const StripeProductFragment = (0, client_1.gql) `
  fragment StripeProductFragment on StripeProductType {
    id
    active
    caption
    metadata
    description
    name
    type
    plans {
      ...StripePlanFragment
    }
  }
  ${stripe_1.StripePlanFragment}
`;
const SUBSCRIPTION_PRODUCTS_QUERY = (0, client_1.gql) `
  query Products($startingAfter: String, $limit: Int) {
    subscriptionProducts(startingAfter: $startingAfter, limit: $limit) {
      hasMore
      data {
        ...StripeProductFragment
      }
    }
  }
  ${StripeProductFragment}
`;
exports.SubscriptionProductsData = (0, utils_1.buildQuery)({
    query: SUBSCRIPTION_PRODUCTS_QUERY,
    defaultVariables: { limit: 10 },
});
const getSubscriptionProducts = () => helpers_1.Client.getClient().query({
    query: SUBSCRIPTION_PRODUCTS_QUERY,
    fetchPolicy: 'network-only',
});
exports.getSubscriptionProducts = getSubscriptionProducts;
