"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultMetaDataFragment = exports.PageInfoFragment = void 0;
const client_1 = require("@apollo/client");
exports.PageInfoFragment = (0, client_1.gql) `
  fragment PageInfoFragment on PageInfoType {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;
exports.DefaultMetaDataFragment = (0, client_1.gql) `
  fragment DefaultMetaDataFragment on DefaultPaginationMetaType {
    total
  }
`;
