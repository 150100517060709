"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpcomingInvoice = exports.UPCOMING_INVOICE_QUERY = exports.InvoicesData = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const stripe_1 = require("./stripe");
// export const InvoiceFragment = gql`
//   fragment InvoiceFragment on Invoice {
//     id
//     isPaid
//     amount
//     stripeInvoiceId
//     stripeReceipt
//   }
// `
// export const USER_INVOICES_QUERY = gql`
//   query UserInvoices {
//     userInvoices {
//       ...InvoiceFragment
//     }
//   }
//   ${InvoiceFragment}
// `
// export const UserInvoicesData = buildQuery<{ userInvoices: Invoice[] }>({
//   query: USER_INVOICES_QUERY,
// })
// export const UNPAID_USER_INVOICES_QUERY = gql`
//   query UnpaidUserInvoices {
//     unpaidInvoice {
//       ...InvoiceFragment
//     }
//   }
//   ${InvoiceFragment}
// `
// export const UnpaidUserInvoicesData = buildQuery<{ unpaidInvoice: Invoice[] }>({
//   query: UNPAID_USER_INVOICES_QUERY,
// })
// export const GENERATE_INVOICE_MUTATION = gql`
//   mutation generateInvoiceForSearches {
//     generateInvoiceForSearches {
//       ...InvoiceFragment
//     }
//   }
//   ${InvoiceFragment}
// `
// export const generateInvoiceForSearches = mutateFunction<{
//   generateInvoiceForSearches: Invoice
// }>({
//   mutation: GENERATE_INVOICE_MUTATION,
// })
const GET_INVOICE_QUERY = (0, client_1.gql) `
  query Invoices($startingAfter: String, $limit: Int) {
    invoices(startingAfter: $startingAfter, limit: $limit) {
      hasMore
      data {
        ...StripeInvoiceFragment
      }
    }
  }
  ${stripe_1.StripeInvoiceFragment}
`;
exports.InvoicesData = (0, utils_1.buildQuery)({
    query: GET_INVOICE_QUERY,
    defaultVariables: {
        limit: 10,
    },
});
exports.UPCOMING_INVOICE_QUERY = (0, client_1.gql) `
  query UpcomingInvoice {
    upcomingInvoice {
      ...StripeInvoiceFragment
    }
  }
  ${stripe_1.StripeInvoiceFragment}
`;
exports.UpcomingInvoice = (0, utils_1.buildQuery)({
    query: exports.UPCOMING_INVOICE_QUERY,
});
