import { Env } from '@curvo/apollo'

const sharedEnv = {
  STRIPE_API_PK_KEY: Env.stripePk,
  COGNITO_POOL_ID: Env.poolId,
  COGNITO_CLIENT_ID: Env.clientId,
}

const env = {
  ...sharedEnv,
}

export default env
