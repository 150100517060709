"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSegmentMutation = exports.SEGMENT_DELETE_MUTATION = exports.createSegmentMutation = exports.SEGMENT_CREATE_MUTATION = exports.updateSegmentMutation = exports.SEGMENT_UPDATE_MUTATION = exports.exportAllSegmentsToCsv = exports.EXPORT_SEGMENTS = exports.SegmentsData = exports.SEGMENTS_QUERY = exports.SegmentFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const pageInfo_1 = require("./pageInfo");
exports.SegmentFragment = (0, client_1.gql) `
  fragment SegmentFragment on Segment {
    id
    name
  }
`;
exports.SEGMENTS_QUERY = (0, client_1.gql) `
  query Segments($before: String, $after: String, $first: Int, $last: Int, $skip: Int, $searchText: String) {
    segments(before: $before, after: $after, first: $first, last: $last, skip: $skip, searchText: $searchText) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...SegmentFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.SegmentFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.SegmentsData = (0, utils_1.buildQuery)({
    query: exports.SEGMENTS_QUERY,
    fetchMoreOpts: ({ variables, data }) => {
        const pageInfo = data && data.segments && data.segments.pageInfo;
        if (!pageInfo || !pageInfo.hasNextPage) {
            return undefined;
        }
        return {
            variables: { first: variables === null || variables === void 0 ? void 0 : variables.first, after: pageInfo.endCursor },
            updateQuery: (p, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.segments) {
                    return p;
                }
                const pEdges = p.segments.edges || [];
                const nEdges = fetchMoreResult.segments.edges || [];
                return {
                    segments: {
                        ...p.segments,
                        edges: [...pEdges, ...nEdges],
                        pageInfo: fetchMoreResult.segments.pageInfo,
                    },
                };
            },
        };
    },
});
exports.EXPORT_SEGMENTS = (0, client_1.gql) `
  mutation exportAllSegmentsToCsv {
    exportAllSegmentsToCsv
  }
`;
exports.exportAllSegmentsToCsv = (0, utils_1.mutateFunction)({
    mutation: exports.EXPORT_SEGMENTS,
});
exports.SEGMENT_UPDATE_MUTATION = (0, client_1.gql) `
  mutation updateSegment($input: SegmentUpdateInput!) {
    updateSegment(input: $input) {
      ...SegmentFragment
    }
  }
  ${exports.SegmentFragment}
`;
exports.updateSegmentMutation = (0, utils_1.mutateFunction)({
    mutation: exports.SEGMENT_UPDATE_MUTATION,
});
exports.SEGMENT_CREATE_MUTATION = (0, client_1.gql) `
  mutation createSegment($input: SegmentCreateInput!) {
    createSegment(input: $input) {
      ...SegmentFragment
    }
  }
  ${exports.SegmentFragment}
`;
exports.createSegmentMutation = (0, utils_1.mutateFunction)({
    mutation: exports.SEGMENT_CREATE_MUTATION,
});
exports.SEGMENT_DELETE_MUTATION = (0, client_1.gql) `
  mutation deleteSegment($id: ID!) {
    deleteSegment(id: $id) {
      ...SegmentFragment
    }
  }
  ${exports.SegmentFragment}
`;
exports.deleteSegmentMutation = (0, utils_1.mutateFunction)({
    mutation: exports.SEGMENT_DELETE_MUTATION,
});
