"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findAndReplaceStudyTransactionsMutation = exports.bulkUpdateBamfToHIMatchesMutation = exports.updatePurchaseOrderTransactionMutation = exports.createNewPartsFromStudyTransaction = exports.bulkUpdateStudyTransactionsMutation = exports.useStudyTransactionsData = exports.StudyTransactionsData = exports.STUDY_TRANSACTIONS = exports.StudyTransactionFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const bulkUpdatePart_1 = require("../graphql/bulkUpdatePart");
const construct_1 = require("../graphql/construct");
const pageInfo_1 = require("./pageInfo");
const purchaseOrder_1 = require("./purchaseOrder");
exports.StudyTransactionFragment = (0, client_1.gql) `
  fragment StudyTransactionFragment on StudyTransactionType {
    id
    tsId
    ponum
    bamfPartId
    bamfPartNumber
    bamfManufacturer
    bamfPartDescription
    matchManufacturerId
    matchPartId
    vendor
    venitem
    manufacturer
    mfgitem
    description
    state
    unitprice
    blacklistVendor
    blacklistVenitem
    ignoreVendor
    qtypurchase
    extprice
    datepurchase
    waste
    isApproved

    gicId
    gicName
    materialId
    materialName
    typeOneId
    typeOneName
    typeTwoId
    typeTwoName
    segmentId
    segmentName
    sizeOne
    sizeTwo
    sizeThree
    constructName
    constructGroup
    constructProcedureType
  }
`;
exports.STUDY_TRANSACTIONS = (0, client_1.gql) `
  query studyTransactions(
    $studyId: Int!
    $ponum: String
    $state: StudyTransactionState
    $first: Int
    $last: Int
    $skip: Int
    $before: String
    $after: String
  ) {
    studyTransactions(
      studyId: $studyId
      first: $first
      last: $last
      skip: $skip
      before: $before
      after: $after
      ponum: $ponum
      state: $state
    ) {
      edges {
        node {
          ...StudyTransactionFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.StudyTransactionFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.StudyTransactionsData = (0, utils_1.buildQuery)({
    query: exports.STUDY_TRANSACTIONS,
});
function useStudyTransactionsData(options) {
    return (0, utils_1.useQueryFunction)(exports.STUDY_TRANSACTIONS, options);
}
exports.useStudyTransactionsData = useStudyTransactionsData;
const STUDY_TRANSACTIONS_UPDATE = (0, client_1.gql) `
  mutation updateBulkStudyTransactions($input: StudyTransactionBulkUpdateInput!) {
    updateBulkStudyTransactions(input: $input) {
      ...StudyTransactionFragment
      purchaseOrder {
        ...PurchaseOrderFragment
        construct {
          ...ConstructFragment
        }
      }
    }
  }
  ${exports.StudyTransactionFragment}
  ${purchaseOrder_1.PurchaseOrderFragment}
  ${construct_1.ConstructFragment}
`;
exports.bulkUpdateStudyTransactionsMutation = (0, utils_1.mutateFunction)({ mutation: STUDY_TRANSACTIONS_UPDATE });
const NEW_PART_FROM_STUDY_TRANSACTION = (0, client_1.gql) `
  mutation createNewPartsFromStudyTransaction($inputs: [NewPartInputType!]!) {
    createNewPartsFromStudyTransaction(inputs: $inputs) {
      ...QueuedUpdatePartFragment
    }
  }
  ${bulkUpdatePart_1.QueuedUpdatePartFragment}
`;
exports.createNewPartsFromStudyTransaction = (0, utils_1.mutateFunction)({
    mutation: NEW_PART_FROM_STUDY_TRANSACTION,
});
const UPDATE_PURCHASE_ORDER_TRANSACTION = (0, client_1.gql) `
  mutation updatePurchaseOrderTransaction($input: PurchaseOrderTransactionUpdateInput!) {
    updatePurchaseOrderTransaction(input: $input) {
      ...StudyTransactionFragment
      purchaseOrder {
        ...PurchaseOrderFragment
      }
    }
  }
  ${exports.StudyTransactionFragment}
  ${purchaseOrder_1.PurchaseOrderFragment}
`;
exports.updatePurchaseOrderTransactionMutation = (0, utils_1.mutateFunction)({
    mutation: UPDATE_PURCHASE_ORDER_TRANSACTION,
});
const BULK_UPDATE_BAMF_TO_HI_MATCHES = (0, client_1.gql) `
  mutation bulkUpdateBamfToHIMatches($input: BamfToHIMatchesBulkUpdateInput!) {
    bulkUpdateBamfToHIMatches(input: $input) {
      ...StudyTransactionFragment
    }
  }
  ${exports.StudyTransactionFragment}
`;
exports.bulkUpdateBamfToHIMatchesMutation = (0, utils_1.mutateFunction)({
    mutation: BULK_UPDATE_BAMF_TO_HI_MATCHES,
});
const FIND_AND_REPLACE_IN_STUDY_TRANSACTIONS = (0, client_1.gql) `
  mutation findAndReplaceStudyTransactions($input: StudyTransactionFindAndReplaceInput!) {
    findAndReplaceStudyTransactions(input: $input)
  }
`;
exports.findAndReplaceStudyTransactionsMutation = (0, utils_1.mutateFunction)({
    mutation: FIND_AND_REPLACE_IN_STUDY_TRANSACTIONS,
});
