"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchSuggestionData = exports.CountResultData = exports.CountFilterData = exports.AdminSearchData = exports.SearchData = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const part_1 = require("./part");
const SEARCH_QUERY = (0, client_1.gql) `
  query search(
    $searchText: String
    $limit: Int = 10
    $offset: Int
    $args: SearchPartInput
    $sizeConditions: SizeConditionInput
    $partialPartNumber: String
    $partialPartDesc: String
    $manufacturerId: String
  ) {
    search(
      searchText: $searchText
      limit: $limit
      offset: $offset
      args: $args
      sizeConditions: $sizeConditions
      partialPartNumber: $partialPartNumber
      partialPartDesc: $partialPartDesc
      manufacturerId: $manufacturerId
    ) {
      data {
        ...PartFragment
      }
      total
    }
  }
  ${part_1.PartFragment}
`;
exports.SearchData = (0, utils_1.buildQuery)({
    query: SEARCH_QUERY,
    notifyOnNetworkStatusChange: true,
});
const ADMIN_SEARCH_QUERY = (0, client_1.gql) `
  query adminSearch(
    $searchText: String
    $limit: Int = 10
    $offset: Int
    $args: SearchPartInput
    $sizeConditions: SizeConditionInput
    $partialPartNumber: String
    $partialPartDesc: String
    $manufacturerId: String
  ) {
    adminSearch(
      searchText: $searchText
      limit: $limit
      offset: $offset
      args: $args
      sizeConditions: $sizeConditions
      partialPartNumber: $partialPartNumber
      partialPartDesc: $partialPartDesc
      manufacturerId: $manufacturerId
    ) {
      data {
        ...PartFragment
      }
      total
    }
  }
  ${part_1.PartFragment}
`;
exports.AdminSearchData = (0, utils_1.buildQuery)({
    query: ADMIN_SEARCH_QUERY,
    notifyOnNetworkStatusChange: true,
});
const COUNT_FILTER_QUERY = (0, client_1.gql) `
  query countFilters(
    $searchText: String
    $before: String
    $after: String
    $first: Int = 10
    $last: Int
    $skip: Int
    $args: SearchPartInput
    $sizeConditions: SizeConditionInput
    $partialPartNumber: String
    $partialPartDesc: String
  ) {
    countFilters(
      searchText: $searchText
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      args: $args
      sizeConditions: $sizeConditions
      partialPartNumber: $partialPartNumber
      partialPartDesc: $partialPartDesc
    ) {
      manufacturer {
        id
        name
        partsCount
      }
      gics {
        id
        name
        partsCount
      }
    }
  }
`;
exports.CountFilterData = (0, utils_1.buildQuery)({
    query: COUNT_FILTER_QUERY,
});
const COUNT_RESULT_QUERY = (0, client_1.gql) `
  query CountResults(
    $searchText: String
    $before: String
    $after: String
    $first: Int = 10
    $last: Int
    $skip: Int
    $args: SearchPartInput
    $sizeConditions: SizeConditionInput
    $partialPartNumber: String
    $partialPartDesc: String
  ) {
    countResults(
      searchText: $searchText
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      args: $args
      sizeConditions: $sizeConditions
      partialPartNumber: $partialPartNumber
      partialPartDesc: $partialPartDesc
    )
  }
`;
const SEARCH_SUGGESTION_QUERY = (0, client_1.gql) `
  query searchSuggestion($searchText: String!) {
    searchSuggestion(searchText: $searchText) {
      matchText
      items {
        type
        text
      }
    }
  }
`;
exports.CountResultData = (0, utils_1.buildQuery)({
    query: COUNT_RESULT_QUERY,
});
exports.SearchSuggestionData = (0, utils_1.buildQuery)({
    query: SEARCH_SUGGESTION_QUERY,
});
